// Generic Helper function to render KPI layout
export function RenderSubKPI({context, kpiText, kpiValue, kpiIconColour, kpiIcon, category, filterValue}){

    let isKpiSelected = false;
    if ((typeof context.kpiFilter[category] === "object") &&
         (JSON.stringify(context.kpiFilter[category]) === JSON.stringify(filterValue))){
            isKpiSelected = true;
    }

    const backgroundColor = isKpiSelected ? "bg-blue-200": "bg-bubble-bg-l"
    return (
        <div className="bubs-dynamic" onClick={() => {
                // TODO ; Remove the condition once KPI filtering support added for Request status page.
                if(category !== undefined){
                    context.UpdateSelectedKpiFilter({[category]: filterValue})
                    // setIsSelected(!isSelected);
                }

                }}>
            <div className={`bubs-dash-kpi ${backgroundColor}  `}>
                <div className="flex justify-content-between mb-3">
                    <div>
                        <span className="block text-kpi-header font-medium mb-3">{kpiText}</span>
                        <div className="text-900 font-medium text-xl">{kpiValue}</div>
                    </div>
                    <div className="px-1"></div>
                    <div className={`flex align-items-center justify-content-center bg-${kpiIconColour}-100 rounded-xl`} style={{ width: '2.5rem', height: '2.5rem' }}>
                        {/* text-${kpiIconColour} -> Must be pre-compiled before use. Add to safelist in tailwind.config.js */}
                        <i className={`pi ${kpiIcon} text-${kpiIconColour}-500 text-xl`}></i>
                    </div>
                </div>
            </div>
        </div>
    );

}



export function AccordionHeaderDiv (statusName, statusRefreshInterval) {
    return (
        <div className="flex align-items-center">
        <span className="vertical-align-middle ">{statusName}</span>
        <span className="vertical-align-middle  text-right text-table-info ml-2"> [Refresh Interval: {statusRefreshInterval}] </span>
    </div>
    );
}

export const TablePreFilterConfig = {
    'Ip': ['XiOIp', 'XiOEastIp', 'XiOWestIp', 'WeatherAllowIp', 'WeatherDenyIp',
    'FirmwareDownloadDenyIp', 'FirmwareDownloadAllowIp', 'FirmwareDownloadAllowIp_Path',
    'FirmwareDownloadDenyIp_Path'],
    'Device': ['XiODevice', 'XiOEastDevice', 'XiOWestDevice'],
    'Country': ['XiOCountry', 'XiOEastCountry', 'XiOWestCountry', 'FirmwareDownloadCountry']
}

export const TableKpiFilterConfig = {
    'XiOIp': {
        'category': 'XiOIp', 
        'filterValue': {"blockStatus": "Allow", 'service': 'XiO'}},
    'XiOWestIp': {
        'category': 'XiOWestIp', 
        'filterValue': {"blockStatus": "Allow", 'service': 'XiO', 'region': 'West'}},
    'XiOEastIp': {
        'category': 'XiOEastIp', 
        'filterValue': {"blockStatus": "Allow", 'service': 'XiO', 'region': 'East'}},
    'XiOCountry': {
        'category': 'XiOCountry', 
        'filterValue': {"blockStatus": "Block", 'service': 'XiO'}},
    'XiOWestCountry': {
        'category': 'XiOWestCountry', 
        'filterValue': {"blockStatus": "Block", 'service': 'XiO', 'region': 'West'}},
    'XiOEastCountry': {
        'category': 'XiOEastCountry', 
        'filterValue': {"blockStatus": "Block", 'service': 'XiO', 'region': 'East'}},
    'XiODevice': {
        'category': 'XiODevice', 
        'filterValue': {"blockStatus": "Disabled", 'service': 'XiO'}},
    'XiOWestDevice': {
        'category': 'XiOWestDevice', 
        'filterValue': {"blockStatus": "Disabled", 'service': 'XiO', "region": "West"}},
    'XiOEastDevice': {
        'category': 'XiOEastDevice', 
        'filterValue': {"blockStatus": "Disabled", 'service': 'XiO', 'region': "East"}},
    'WeatherAllowIp': {
        'category': 'WeatherAllowIp',
        'filterValue': {"blockStatus": "Allow", 'service': 'Weather'}},
    'WeatherDenyIp': {
        'category': 'WeatherDenyIp', 
        'filterValue': {"blockStatus": "Deny", 'service': 'Weather'}},
    'FirmwareDownloadDenyIp': {
        'category': 'FirmwareDownloadDenyIp', 
        'filterValue': {"blockStatus": "Deny", 'service': 'Firmware Download',
        'ruleType': 'ip'}},
    'FirmwareDownloadAllowIp': {
        'category': 'FirmwareDownloadAllowIp', 
        'filterValue': {"blockStatus": "Allow", 'service': 'Firmware Download',
        'ruleType': 'ip'}},
    'FirmwareDownloadDenyIp_Path': {
        'category': 'FirmwareDownloadDenyIp_Path', 
        'filterValue': {"blockStatus": "Deny", 'service': 'Firmware Download',
        'ruleType': 'ip&path' }},
    'FirmwareDownloadAllowIp_Path': {
        'category': 'FirmwareDownloadAllowIp_Path', 
        'filterValue': {"blockStatus": "Allow", 'service': 'Firmware Download',
        'ruleType': 'ip&path'}},
    'FirmwareDownloadCountry': {
        'category': 'FirmwareDownloadCountry', 
        'filterValue': {"blockStatus": "Block", 'service': 'Firmware Download',
        'ruleType': 'country'}},
}