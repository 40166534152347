import { useContext } from "react";

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';


import React from 'react';

import { Accordion, AccordionTab } from 'primereact/accordion';

import { RulesContext } from "../../ui-components/ContextManager";

import { CountryRuleTable } from "../tables/CountryRulesTable";
import { IPRuleTable } from "../tables/IpRulesTable";

import { RenderSubKPI, TableKpiFilterConfig, AccordionHeaderDiv } from "../../utils/layout_util";

import { BlockStatusReport } from "../BlockStatus";

// Firmware Download KPIs

function AkamaiKPI1({rulesContext}) {  
    return (
            <div className="flex flex-wrap justify-center gap-x-2 gap-y-1 text-1xl ">
                <RenderSubKPI context={rulesContext} kpiText="Blocked Countries"
                    kpiValue={rulesContext.generalStatus.totalAkamaiDenyCountriesCount}
                    kpiIconColour="red" kpiIcon="pi-shield" 
                    category={TableKpiFilterConfig.FirmwareDownloadCountry.category}
                    filterValue={TableKpiFilterConfig.FirmwareDownloadCountry.filterValue}/>
            </div>
    )
  }

  
function AkamaiKPI2({rulesContext}) {
    return (
        <div className="flex flex-wrap grow strech justify-center gap-x-2 gap-y-1 text-1xl ">
            <RenderSubKPI context={rulesContext} kpiText="Deny IP&emsp;&emsp;&emsp;&nbsp;" 
                kpiValue={rulesContext.generalStatus.totalAkamaiDenyIPsCount} 
                kpiIconColour="red" kpiIcon="pi-shield"
                category={TableKpiFilterConfig.FirmwareDownloadDenyIp.category}
                filterValue={TableKpiFilterConfig.FirmwareDownloadDenyIp.filterValue}/>
            <RenderSubKPI context={rulesContext} kpiText="Deny IP & Path" 
                kpiValue={rulesContext.generalStatus['totalAkamaiDenyPath+IPsCount']} 
                kpiIconColour="red" kpiIcon="pi-shield"
                category={TableKpiFilterConfig.FirmwareDownloadDenyIp_Path.category}
                filterValue={TableKpiFilterConfig.FirmwareDownloadDenyIp_Path.filterValue}/>

        </div>
    )
}


function AkamaiKPI3({rulesContext}) {
    return (
        <div className="flex flex-wrap grow strech justify-center gap-x-2 gap-y-1 text-1xl ">
            <RenderSubKPI context={rulesContext} kpiText="Allow IP&emsp;&emsp;&emsp;" 
                kpiValue={rulesContext.generalStatus['totalAkamaiAllowIPsCount']}
                kpiIconColour="green" kpiIcon="pi-shield"
                category={TableKpiFilterConfig.FirmwareDownloadAllowIp.category}
                filterValue={TableKpiFilterConfig.FirmwareDownloadAllowIp.filterValue}/>
            <RenderSubKPI context={rulesContext} kpiText="Allow IP & Path" 
                kpiValue={rulesContext.generalStatus['totalAkamaiAllowPath+IPsCount']}
                kpiIconColour="green" kpiIcon="pi-shield"
                category={TableKpiFilterConfig.FirmwareDownloadAllowIp_Path.category}
                filterValue={TableKpiFilterConfig.FirmwareDownloadAllowIp_Path.filterValue}/>
        </div>
    )
}


// Rendering KPI layout
function KPILayout({rulesContext}){
    return (

        <div className="flex flex-wrap justify-center gap-x-1 gap-y-1  text-1xl ">
           <div className="bubs-dynamic">
                    <div className="bubs-group-kpi">
                        <span className="block text-kpi-group-header  mb-1 text-center">Blocked Countries</span>
                        <AkamaiKPI1 rulesContext={rulesContext}/>
                    </div>
            </div> 

            <div className="bubs-dynamic">
                    <div className="bubs-group-kpi">
                        <span className="block text-kpi-group-header  mb-1 text-center">Deny IP</span>
                        <AkamaiKPI2 rulesContext={rulesContext}/>
                    </div>
            </div> 
            <div className="bubs-dynamic">
                    <div className="bubs-group-kpi">
                        <span className="block text-kpi-group-header  mb-1 text-center">Allow IP</span>
                        <AkamaiKPI3 rulesContext={rulesContext}/>
                    </div>
            </div> 

        </div>

    )
}


// Rendering Dashboard Components
function RenderFirmwareDownloadBlockStatusReport() {
    const rulesContext = useContext(RulesContext);
    return (
      <>
        <div>
            <KPILayout rulesContext={rulesContext}/>
            <Accordion multiple className="w-full overflow-auto mt-3"> 
                {rulesContext.displayIpTable > 0 && 
                    <AccordionTab header={AccordionHeaderDiv("Status by IP", rulesContext.generalStatus.AkamaiAutomationReadInternal)}>
                    <IPRuleTable rulesObj={rulesContext.akamai.IPs} ipRuleContext={rulesContext}
                    tablePage={"ViewPage"} tableCrud={['Delete']}/>
                </AccordionTab>}
                {rulesContext.displayCountryTable > 0 && 
                    <AccordionTab header={AccordionHeaderDiv("Status by Country", rulesContext.generalStatus.AkamaiAutomationReadInternal)}>
                    <CountryRuleTable  rulesObj={rulesContext.akamai.Countries}  countryRuleContext={rulesContext}
                    tablePage={"ViewPage"} tableCrud={['Delete']}/>
                </AccordionTab>}
          </Accordion>
        </div>
      </>
    )
  }
  
// Embedding  Rendered Dashboard to Main layout
export function FirmwareDownloadBlockStatusReport() {
    return BlockStatusReport(RenderFirmwareDownloadBlockStatusReport);
}
