export const ipRuleTableSchema = {
    'ruleId': {
        header: "# Rule ID",
        filterMenuStyle: { width: '14rem' }, 
        style: { minWidth: '90px' },
        defaultVisible: false
    },
    'ip': {
        header: "IP Address",
        style: { minWidth: '110px' },
        filterMenuStyle: { width: '14rem' } ,
        showFilterMatchModes: false,
        // className: "hover:font-bold"
    },
    'requestState': {
        header: "Request State",
        filterMenuStyle: { width: '14rem' }, 
        style: { minWidth: '90px' },
    },
    'requestTime': {
        header: "Request Time",
        filterMenuStyle: { width: '14rem' }, 
        style: { minWidth: '90px' },
        defaultVisible: false,
    },
    'requestedByUser': {
        header: "RequestedBy User",
        filterMenuStyle: { width: '14rem' }, 
        style: { minWidth: '90px' },
    },
    'requestedByUserName': {
        header: "RequestedBy Username",
        filterMenuStyle: { width: '14rem' }, 
        style: { minWidth: '90px' },
        defaultVisible: false,
    },
    'requestDescription': {
        header: "Request Comment",
        filterMenuStyle: { width: '14rem' }, 
        style: { minWidth: '90px' },

    },
    'lastUpdateTime': {
        header: "Last Update Time",
        filterMenuStyle: { width: '14rem' }, 
        style: { minWidth: '90px' },
        defaultVisible: false,
    },
    'lastUpdatedByUser': {
        header: "Last UpdatedBy User",
        filterMenuStyle: { width: '14rem' }, 
        style: { minWidth: '90px' },
    },
    'lastUpdatedByUserName': {
        header: "Last UpdatedBy Username",
        filterMenuStyle: { width: '14rem' }, 
        style: { minWidth: '90px' },
        defaultVisible: false,
    },
    'approveDescription': {
        header: "Approve/Reject Comment",
        filterMenuStyle: { width: '14rem' }, 
        style: { minWidth: '90px' },
    },
    'service': {
        header: "Service",
        style: { minWidth: '200px' },
        showFilterMatchModes: false,
    },
    'blockStatus': {
        header: "Block Status",
        defaultVisible: true,
        style: { minWidth: '90px' },
        showFilterMatchModes: false,
    },
    'region': {
        header: "Block Region",
        style: { minWidth: '90px' },
        showFilterMatchModes: false,
    },
    'ruleSource': {
        header: "Rule Source",
        style: { minWidth: '100px' },
        filterMenuStyle: { width: '14rem' },
        showFilterMatchModes: false,
        defaultVisible: false
    },
    'ruleName': {
        header: "Rule Name",
        filterMenuStyle: { width: '14rem' }, 
        style: { minWidth: '90px' },
        defaultVisible: false
    },
    // 'ruleType': {
    //     header: "Rule Name",
    //     filterMenuStyle: { width: '14rem' }, 
    //     style: { minWidth: '90px' },
    //     defaultVisible: false
    // },
    'path': {
        header: "Firmware path",
        style: { minWidth: '270px' },
    }
}
