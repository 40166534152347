import React, { useState } from "react";
import IconButton from '@mui/material/IconButton';
import { WidgetsOutlined, HomeOutlined } from "@mui/icons-material";


import { Sidebar } from "primereact/sidebar";


import {  HomeMenu, ReportMenu, RequestMenu } from "./AppMenu";


function SideBar({visible, setVisible}) {
    
    const customIcons = (
        <React.Fragment>
            <button className="p-sidebar-icon p-link mr-2">
                {/* Add search if required , future use */}
                {/* <span className="pi pi-search" /> */}
            </button>
        </React.Fragment>
    );
    
    return (
        <div className="block">
            <Sidebar visible={visible} onHide={() => setVisible(false)} icons={customIcons}>
                <div className=" font-semibold text-xl">Home</div>
                <HomeMenu/>
                <div className=" font-semibold text-xl">Report</div>
                <ReportMenu/>
                <div className=" font-semibold text-xl">Status</div>
                <RequestMenu/>
            </Sidebar>


        </div>
    )
}

export const MenuButton = (props) => {
    const [visible, setVisible] = useState(false);
    return (
        <div className="pt-1">
            <SideBar className="block" visible={visible} setVisible={setVisible}/>
            {/* <IconButton href="help.html"  target="_blank" className="hover:text-white text-white"> 
                <HelpOutlineOutlined />
            </IconButton> */}
            <IconButton href="home" className="hover:text-white text-white"> 
                <HomeOutlined />
            </IconButton>
            <IconButton className="pt-1"
                onClick={() => setVisible(true)}
                color="inherit">
                <WidgetsOutlined /> 
            </IconButton>
        </div>
    )
};

export default MenuButton;