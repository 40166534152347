import React, { useContext } from "react";

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import { Accordion, AccordionTab } from 'primereact/accordion';

import { RulesRequestContext} from "../../ui-components/ContextManager";

import { IPRuleTable } from "../tables/IpRulesTable";
import { CountryRuleTable } from "../tables/CountryRulesTable";
import { IoTRuleTable } from "../tables/IotRulesTable";

import { RequestStatusReport } from "./RequestStatus";
import { RenderSubKPI } from "../../utils/layout_util";



function XiOPendingRequests({rulesContext}){
  return (
      <div className="flex flex-wrap justify-center gap-x-2 gap-y-1 text-1xl">
     <RenderSubKPI context={rulesContext} kpiText="Requested" kpiValue={rulesContext.stats.XiO.Requested}
      kpiIconColour="blue" kpiIcon="pi-clock"
      // category={TableKpiFilterConfig.WeatherAllowIp.category}
      // filterValue={TableKpiFilterConfig.WeatherAllowIp.filterValue}
      />
      <RenderSubKPI context={rulesContext} kpiText="Approved / Success" 
        kpiValue={`${rulesContext.stats.XiO.Approved} / ${rulesContext.stats.XiO.Success}`}
        kpiIconColour="green" kpiIcon="pi-check"
        // category={TableKpiFilterConfig.WeatherAllowIp.category}
        // filterValue={TableKpiFilterConfig.WeatherAllowIp.filterValue}
      />
      <RenderSubKPI context={rulesContext} kpiText="Rejected / Failed"
        kpiValue={`${rulesContext.stats.XiO.Rejected} / ${rulesContext.stats.XiO.Failed} `}
        kpiIconColour="red" kpiIcon="pi-times"
        // category={TableKpiFilterConfig.WeatherAllowIp.category}
        // filterValue={TableKpiFilterConfig.WeatherAllowIp.filterValue}
      />
  </div>

  )
}



function FirmwareDownloadPendingRequests({rulesContext}){
  return (
    <div className="flex flex-wrap justify-center gap-x-2 gap-y-1 text-1xl">
    <RenderSubKPI context={rulesContext} kpiText="Requested" kpiValue={rulesContext.stats["Firmware Download"].Requested}
      kpiIconColour="blue" kpiIcon="pi-clock"
      // category={TableKpiFilterConfig.WeatherAllowIp.category}
      // filterValue={TableKpiFilterConfig.WeatherAllowIp.filterValue}
     />
    <RenderSubKPI context={rulesContext} kpiText="Approve / Success" 
      kpiValue={`${rulesContext.stats["Firmware Download"].Approved} / ${rulesContext.stats["Firmware Download"].Success}`}
      kpiIconColour="green" kpiIcon="pi-check"
      // category={TableKpiFilterConfig.WeatherAllowIp.category}
      // filterValue={TableKpiFilterConfig.WeatherAllowIp.filterValue}
     />
    <RenderSubKPI context={rulesContext} kpiText="Rejected / Failed" 
        kpiValue={`${rulesContext.stats["Firmware Download"].Rejected} / ${rulesContext.stats["Firmware Download"].Failed}`}
        kpiIconColour="red" kpiIcon="pi-times"
        // category={TableKpiFilterConfig.WeatherAllowIp.category}
        // filterValue={TableKpiFilterConfig.WeatherAllowIp.filterValue}
     />
 </div>

  )
}

function WeatherPendingRequests({rulesContext}){
  return (
    <div className="flex flex-wrap justify-center gap-x-2 gap-y-1 text-1xl">
    <RenderSubKPI context={rulesContext} kpiText="Requested" kpiValue={rulesContext.stats.Weather.Requested}
     kpiIconColour="blue" kpiIcon="pi-clock"
     // category={TableKpiFilterConfig.WeatherAllowIp.category}
     // filterValue={TableKpiFilterConfig.WeatherAllowIp.filterValue}
     />
    <RenderSubKPI context={rulesContext} kpiText="Approved / Success" 
        kpiValue={`${rulesContext.stats.Weather.Approved} / ${rulesContext.stats.Weather.Success}`}
        kpiIconColour="green" kpiIcon="pi-check"
        // category={TableKpiFilterConfig.WeatherAllowIp.category}
        // filterValue={TableKpiFilterConfig.WeatherAllowIp.filterValue}
     />
    <RenderSubKPI context={rulesContext} kpiText="Rejected / Failed"
         kpiValue={`${rulesContext.stats.Weather.Rejected} / ${rulesContext.stats.Weather.Failed}`}
        kpiIconColour="red" kpiIcon="pi-times"
        // category={TableKpiFilterConfig.WeatherAllowIp.category}
        // filterValue={TableKpiFilterConfig.WeatherAllowIp.filterValue}
     />
 </div>

  )
}

// Rendering Dashboard Components
function KPILayout(){
  const rulesContext = useContext(RulesRequestContext);
  return (

      <div className="flex flex-wrap justify-center gap-x-1 gap-y-1  text-1xl ">
          <div className="bubs-dynamic">
              <div className="bubs-group-kpi">
                  <span className="block text-kpi-group-header  mb-1 text-center">XiO</span>
                  <XiOPendingRequests rulesContext={rulesContext}/>
              </div>
          </div> 
          <div className="bubs-dynamic">
              <div className="bubs-group-kpi">
                  <span className="block text-kpi-group-header  mb-1 text-center">Weather</span>
                  <WeatherPendingRequests rulesContext={rulesContext}/>
              </div>
          </div> 
          <div className="bubs-dynamic">
              <div className="bubs-group-kpi">
                  <span className="block text-kpi-group-header  mb-1 text-center">Firmware Download</span>
                  <FirmwareDownloadPendingRequests rulesContext={rulesContext}/>
              </div>
          </div> 
      </div>
  )
}

// Rendering Dashboard Components
function RenderRequestLayout() {
  const ruleContext = useContext(RulesRequestContext);
    return (
      <>
        <div >
        <KPILayout/>
            <Accordion multiple  className="w-full overflow-auto mt-3"> 

            {ruleContext.displayDeviceTable  && <AccordionTab header="Status by Device">
                    <IoTRuleTable tableName="Device Table" iotRuleContext={ruleContext}
                     tableCrud={['Reject', 'Approve', 'Cancel']} tablePage={"RequestPage"}/>
                </AccordionTab>}
                {ruleContext.displayIpTable  && <AccordionTab header="Status by IP">
                  <IPRuleTable tableName="IP Table" ipRuleContext={ruleContext} 
                  tableCrud={['Reject', 'Approve', 'Cancel']} tablePage={"RequestPage"}/>
                </AccordionTab>}
                {ruleContext.displayCountryTable && <AccordionTab header="Status by Country">
                    <CountryRuleTable tableName="Country Table" countryRuleContext={ruleContext} 
                    tableCrud={['Reject', 'Approve', 'Cancel']} tablePage={"RequestPage"}/>
                </AccordionTab>}

          </Accordion>
        </div>
      </>
    )
  }
  
// Embedding  Rendered Dashboard to Main layout

export function RequestLayout() {
    return RequestStatusReport(RenderRequestLayout)
}

