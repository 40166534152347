export const deviceRuleTableSchema = {
    'ruleId': {
        header: "# Rule ID",
        filterMenuStyle: { width: '14rem' }, 
        style: { minWidth: '90px' },
        defaultVisible: false
    },
    'deviceId': {
        header: "Device ID",
        style: { minWidth: '110px' },
        filterMenuStyle: { width: '14rem' } ,
        showFilterMatchModes: false,
        // className: "hover:font-bold"
    },
    'requestState': {
        header: "Request State",
        filterMenuStyle: { width: '14rem' }, 
        style: { minWidth: '90px' },
    },
    'requestTime': {
        header: "Request Time",
        filterMenuStyle: { width: '14rem' }, 
        style: { minWidth: '90px' },
        defaultVisible: false,
    },
    'requestedByUser': {
        header: "RequestedBy User",
        filterMenuStyle: { width: '14rem' }, 
        style: { minWidth: '90px' },
    },
    'requestedByUserName': {
        header: "RequestedBy Username",
        filterMenuStyle: { width: '14rem' }, 
        style: { minWidth: '90px' },
        defaultVisible: false,
    },
    'requestDescription': {
        header: "Request Comment",
        filterMenuStyle: { width: '14rem' }, 
        style: { maxWidth: '180px' },
    },
    'lastUpdateTime': {
        header: "Last Update Time",
        filterMenuStyle: { width: '14rem' }, 
        style: { minWidth: '90px' },
        defaultVisible: false,
    },
    'lastUpdatedByUser': {
        header: "Last UpdatedBy User",
        filterMenuStyle: { width: '14rem' }, 
        style: { minWidth: '90px' },
    },
    'lastUpdatedByUserName': {
        header: "Last UpdatedBy Username",
        filterMenuStyle: { width: '14rem' }, 
        style: { minWidth: '90px' },
        defaultVisible: false,
    },
    'approveDescription': {
        header: "Approve/Reject Comment",
        filterMenuStyle: { width: '14rem' }, 
        style: { maxWidth: '180px' },
    },
    'DeviceName': {
        header: "Device Name",
        style: { minWidth: '200px' },
        defaultVisible: false,
    },
    'service': {
        header: "Service",
        style: { minWidth: '200px' },
        showFilterMatchModes: false,
    },
    'blockStatus': {
        header: "Block Status",
        defaultVisible: true,
        style: { minWidth: '90px' },
        showFilterMatchModes: false,
    },
    'region': {
        header: "Block Region",
        defaultVisible: true,
        style: { minWidth: '90px' },
        showFilterMatchModes: false,
    },
    'Model': {
        header: "Model",
        style: { minWidth: '100px' },
        filterMenuStyle: { width: '14rem' },
        showFilterMatchModes: false
    },
    'SerialNumber': {
        header: "Serial Number",
        defaultVisible: false,
        style: { minWidth: '90px' }
    },
    'DeviceVersion': {
        header: "Device Version",
        filterMenuStyle: { width: '14rem' }, 
        showFilterMatchModes: false,
        style: { minWidth: '90px' }
    },
    'AccountName': {
        header: "Account Name",
        style: { minWidth: '270px' }
    }
}
