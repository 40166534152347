import { useContext } from "react";

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';


import React from 'react';

import { Accordion, AccordionTab } from 'primereact/accordion';

import { RulesContext } from "../../ui-components/ContextManager";

import { IPRuleTable } from "../tables/IpRulesTable";
import { RenderSubKPI, TableKpiFilterConfig, AccordionHeaderDiv } from "../../utils/layout_util";

import { BlockStatusReport } from "../BlockStatus";






function AllowIPsKPI({rulesContext}){
    return (
        <div className="flex flex-wrap justify-center gap-x-2 gap-y-1 text-1xl">
       <RenderSubKPI context={rulesContext} kpiText="Allow IPs" kpiValue={rulesContext.generalStatus.totalWeatherAllowedIPsCount}
        kpiIconColour="green" kpiIcon="pi-shield"
        category={TableKpiFilterConfig.WeatherAllowIp.category}
        filterValue={TableKpiFilterConfig.WeatherAllowIp.filterValue}/>
      
    </div>

    )
}



function DenyIPsKPI({rulesContext}){
    return (
        <div className="flex flex-wrap justify-center gap-x-2 gap-y-1 text-1xl">
       <RenderSubKPI context={rulesContext} kpiText="Deny IPs" 
        kpiValue={rulesContext.generalStatus.totalWeatherDenyIPsCount}
        kpiIconColour="red" kpiIcon="pi-shield"
        category={TableKpiFilterConfig.WeatherDenyIp.category}
        filterValue={TableKpiFilterConfig.WeatherDenyIp.filterValue}/>
      
    </div>

    )
}

// Rendering Dashboard Components
function KPILayout({rulesContext}){

    return (

        <div className="flex flex-wrap justify-center gap-x-1 gap-y-1  text-1xl ">
            {/* Dummy placeholders  */}
            <div className="bubs-dynamic">
            </div>
            <div className="bubs-dynamic">
            </div>
            <div className="bubs-dynamic">
                <div className="bubs-group-kpi">
                    <span className="block text-kpi-group-header  mb-1 text-center">Allow IP</span>
                    <DenyIPsKPI rulesContext={rulesContext}/>
                </div>
            </div> 
            <div className="bubs-dynamic">
                <div className="bubs-group-kpi">
                    <span className="block text-kpi-group-header  mb-1 text-center">Deny IP</span>
                    <AllowIPsKPI rulesContext={rulesContext}/>
                </div>
            </div> 
            {/* Dummy placeholders  */}
            <div className="bubs-dynamic">
            </div>
            <div className="bubs-dynamic">
            </div>
        </div>
    )
}

// Rendering Dashboard Components
function RenderWeatherBlockIPReport() {
    const rulesContext = useContext(RulesContext);
    return (
      <>
        <div>
            <KPILayout rulesContext={rulesContext}/>
            <Accordion multiple className="w-full overflow-auto mt-3"> 
                {rulesContext.displayIpTable &&
                     <AccordionTab header={AccordionHeaderDiv("Status by IP", rulesContext.generalStatus.NSGReadInterval)}>
                    <IPRuleTable rulesObj={rulesContext.weather.IPs} ipRuleContext={rulesContext}
                    tablePage={"ViewPage"} tableCrud={['Delete']} page={"Weather"}/>
                </AccordionTab>}
            </Accordion>
        </div>
      </>
    )
  }
  
// Embedding Rendered Dashboard to Main layout
export function WeatherBlockedIPReport() {
    return BlockStatusReport(RenderWeatherBlockIPReport);
}
