import React from 'react';

import { AuthenticatedTemplate } from "@azure/msal-react";

import SignInSignOutButton from "./SignInSignOutButton";
import MenuButton from "./Menubutton";


const NavBarTitle = {
    '/XiOBlockStatusReport': "XiO Block Status Report",
    '/WeatherBlockStatusReport': "Weather Block Status Report",
    '/FirmwareDownloadBlockStatusReport': "Firmware Download Block Status Report",
    '': "Dashboard",
    '/': "Dashboard",
    "/home": "Dashboard",
    "/Request": "Request Status"
}


const NavBar = () => {
    return (
        <div sx={{ flexGrow: 1}}>
            <div className="w-full grow shrink flex flex-wrap text-center justify-between sticky bg-navigation-bar text-white  pr-3 pt-1 top-0 align-top self-start z-50">
                <div>
                    <img className="h-5 m-2 pt-0.5 block" 
                        alt="Crestron logo" src={require("./crestron_logo.png")}/>
                </div>
                <AuthenticatedTemplate>
                    <div className=" font-semibold p-1">
                    <span style={{fontSize: 27}}>{NavBarTitle[window.location.pathname]}</span>
                    </div>   
                </AuthenticatedTemplate>
                <div className="flex flex-wrap">
                    <AuthenticatedTemplate>
                        <MenuButton/>
                    </AuthenticatedTemplate>
                    <SignInSignOutButton />
                </div>       
            </div>
        </div>
    );
};

export default NavBar;