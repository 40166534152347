import React, { useState, useMemo, useRef, useEffect, useContext } from "react";

import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from "primereact/datatable";

import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';

import { Button } from 'primereact/button';

import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { TablePreFilterConfig } from "../../utils/layout_util";
import { renderTableColumn, renderTableHeader, filterTemplate, 
    blockRegionFilterTemplate, blockStatusFilterTemplate,
     blockStatusBodyTemplate, TablePreFilter, requestRuleUnblock,
     approveRequest, deleteRequest, rejectRequest, isRowSelectable, 
     checkUserDeleteRequest, requestStatusBodyTemplate  } from "../../lib/Table";
import { UserContext } from "../../ui-components/ContextManager";
import RowTable from "../../lib/RowTable";
import { countryRuleTableSchema } from "./CountryRulesTableSchema";

//TODO : User Description support for Rule actions -> Currently not required.

// Country block status table
export function CountryRuleTable(props) {
    const userContext = useContext(UserContext);
    const requestInfo = {'username': userContext.username, 'name' : userContext.name};
    const countryRuleContext = props.countryRuleContext;
    let rulesObj = props.rulesObj === undefined ? countryRuleContext.countryRules: props.rulesObj;
    rulesObj = TablePreFilter(rulesObj, TablePreFilterConfig.Country, countryRuleContext)
    const [Countries, setCountries] = useState([]);

    const toast = useRef(null);
    const tableCrud = props.tableCrud === undefined ? []: props.tableCrud;

    const emptyIpRule = {
        'country': null,
        'service': null,
        'blockStatus': null,
    }

    // Block, Unblock

    const [countryRuleDialog, setCountryRuleDialog] = useState(false);
    const [viewCountryRuleDialog, setViewCountryRuleDialog] = useState(false);
    const [deleteCountryRuleDialog, setDeleteCountryRuleDialog] = useState(false);
    const [deleteCountriesRuleDialog, setdeleteCountriesRuleDialog] = useState(false);
    const [rejectCountryRuleRequestDialog, setRejectCountryRuleRequestDialog] = useState(false);
    const [rejectCountriesRuleRequestDialog, setRejectCountriesRuleRequestDialog] = useState(false);
    const [approveCountryRuleRequestDialog, setApproveCountryRuleRequestDialog] = useState(false);
    const [approveCountriesRuleRequestDialog, setApproveCountriesRuleRequestDialog] = useState(false);
    const [cancelCountryRuleRequestDialog, setCancelCountryRuleRequestDialog] = useState(false);
    const [cancelCountriesRuleRequestDialog, setCancelCountriesRuleRequestDialog] = useState(false);
    const [countryRule, setCountryRule] = useState(emptyIpRule);
    const [selectedRules, setSelectedRules] = useState(null);
    const [submitted, setSubmitted] = useState(false);


    // const openNew = () => {
    //     setCountryRule(emptyIpRule);
    //     setSubmitted(false);
    //     setCountryRuleDialog(true);
    // };

    const hideDialog = () => {
        setSubmitted(false);
        setCountryRuleDialog(false);
    };

    const hideViewCountryRuleDialog = () => {
        setViewCountryRuleDialog(false);
    };

    const hideDeleteCountryRuleDialog = () => {
        setDeleteCountryRuleDialog(false);
    };

    const hideDeleteCountriesRuleDialog = () => {
        setdeleteCountriesRuleDialog(false);
    };


    const hideRejectCountryRuleDialog = () => {
        setRejectCountryRuleRequestDialog(false);
    };

    const hideRejectCountriesRuleDialog = () => {
        setRejectCountriesRuleRequestDialog(false);
    };

    const hideApproveCountryRuleDialog = () => {
        setApproveCountryRuleRequestDialog(false);
    };

    const hideApproveCountriesRuleDialog = () => {
        setApproveCountriesRuleRequestDialog(false);
    };

    const hideCancelCountryRuleDialog = () => {
        setCancelCountryRuleRequestDialog(false);
    };

    const hideCancelCountriesRuleDialog = () => {
        setCancelCountriesRuleRequestDialog(false);
    };

    const saveCountryRule = () => {
        setSubmitted(true);

        if (countryRule.name) {
            let countryRules = [...Countries];
            let _countryRule = { ...countryRule };

            if (_countryRule.id) {
                const index = findIndexById(countryRule.id);

                countryRules[index] = _countryRule;
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Rule Updated', life: 3000 });
            } else {
                countryRule.id = createId();
                // _product.image = 'product-placeholder.svg';
                countryRules.push(_countryRule);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Rule Created', life: 3000 });
            }

            setCountries(countryRules);
            setCountryRuleDialog(false);
            setCountryRule(editCountryRule);
        }
    };

    
    const editCountryRule = (countryRule) => {
        setCountryRule({ ...countryRule });
        setCountryRuleDialog(true);
    };

    const viewCountryRule = (countryRule) => {
        setCountryRule(countryRule);
        setViewCountryRuleDialog(true);
    };

    const confirmDeleteCountryRule = (countryRule) => {
        setCountryRule(countryRule);
        setDeleteCountryRuleDialog(true);
    };

    const confirmApproveCountryRuleRequest = (countryRule) => {
        setCountryRule(countryRule);
        setApproveCountryRuleRequestDialog(true);
    };

    const confirmRejectCountryRuleRequest = (countryRule) => {
        setCountryRule(countryRule);
        setRejectCountryRuleRequestDialog(true);
    };

        
    const confirmCancelCountryRuleRequest = (countryRule) => {
        setCountryRule(countryRule);
        setCancelCountryRuleRequestDialog(true);
    };


    let ApproveRowAction = (rowData) => { return(<></>)};
    let RejectRowAction = (rowData) => { return(<></>)};
    let CancelRowAction = (rowData) => { return(<></>)};
    let EditRowAction = (rowData) => { return(<></>)};
    let DeleteRowAction = (rowData) => { return(<></>)};
    let ViewRowAction = ({rowData}) => { return (
        <Button  icon="pi pi-eye" rounded outlined className="mr-1" 
            onClick={() => viewCountryRule({...rowData})} />)};
    if (tableCrud.includes("Approve")){
        ApproveRowAction = ({rowData, disabled}) => { return( 
        <Button disabled={disabled} icon="pi pi-check" rounded outlined className="mr-1" severity="success"
            onClick={() => confirmApproveCountryRuleRequest({...rowData})} />)};
    }

    if (tableCrud.includes("Reject")){
        RejectRowAction = ({rowData, disabled}) => { return (
            <Button disabled={disabled} icon="pi pi-times" rounded outlined className="mr-1" severity="danger" 
                onClick={() => confirmRejectCountryRuleRequest({...rowData})} />)};
    }
    if (tableCrud.includes("Cancel")){
        CancelRowAction = ({rowData, disabled}) => { return (
            <Button disabled={disabled} icon="pi pi-trash" rounded outlined className="mr-1" severity="danger"
                onClick={() => confirmCancelCountryRuleRequest({...rowData})} />)};
    }
    if (tableCrud.includes("Edit")){
        EditRowAction = ({rowData, disabled}) => { return (
            <Button disabled={disabled} icon="pi pi-pencil" rounded outlined className="mr-1"
                onClick={() => editCountryRule({...rowData})} />)};
    }
    if (tableCrud.includes("Delete")){
        DeleteRowAction = ({rowData, disabled}) => { return (
            <Button disabled={disabled} icon="pi pi-check-circle" rounded outlined className="mr-1" severity="success"
                onClick={() => confirmDeleteCountryRule({...rowData})} />)};
    }
    const actionBodyTemplate = (rowData) => {
        return (
            // For now no need to enable Option for Country Unblock
            <React.Fragment>
                <ViewRowAction rowData={rowData}/>
                <ApproveRowAction rowData={rowData} disabled={true}/>
                <RejectRowAction rowData={rowData} disabled={true}/>
                <CancelRowAction rowData={rowData} disabled={true}/>
                <EditRowAction rowData={rowData} disabled={true}/>
                <DeleteRowAction rowData={rowData} disabled={true}/>  
            </React.Fragment>
        );
    };




    const deleteCountryRule = () => {
        requestRuleUnblock([countryRule], requestInfo,
            {'ref': toast, 'success': "Country enable request submitted",
                "error": "Country enable request failed"})
        // setCountries(countryRules);
        setDeleteCountryRuleDialog(false);
        setCountryRule(emptyIpRule);
        
    };

    const approveCountryRuleRequest = () => {
        
        approveRequest([countryRule], requestInfo,
            {'ref': toast, 'success': "Country rule request approved",
                "error": "Country rule request approval failed"})

        setApproveCountryRuleRequestDialog(false);
        // setCountryRule(emptyIpRule);
        
    };

    const rejectCountryRuleRequest = () => {
        rejectRequest([countryRule], requestInfo,
            {'ref': toast, 'success': "Country rule request rejected",
                "error": "Country rule request rejection failed"})
        setRejectCountryRuleRequestDialog(false);
        // setCountryRule(emptyIpRule);
       
    };

    const cancelCountryRuleRequest = () => {
        if (!checkUserDeleteRequest(selectedRules, requestInfo)){
            toast.current.show({ severity: 'error', summary: 'Validation Failed', detail: 'Not authorized to Delete Other user request.', life: 3000 });
            return
        }
        deleteRequest([countryRule], requestInfo,
        {'ref': toast, 'success': "Country rule request deleted",
                "error": "Country rule request deletion failed"})
        deleteRequest([countryRule], requestInfo)
        setCancelCountryRuleRequestDialog(false);
        // setCountryRule(emptyIpRule);
       
    };

    const findIndexById = (id) => {
        let index = -1;

        for (let i = 0; i < countryRule.length; i++) {
            if (countryRule[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        return id;
    };

    const confirmDeleteSelected = () => {
        setdeleteCountriesRuleDialog(true);
    };
    const confirmApproveSelected = () => {
        setApproveCountriesRuleRequestDialog(true);
    };
    const confirmRejectSelected = () => {
        setRejectCountriesRuleRequestDialog(true);
    };

    const confirmCancelSelected = () => {
        setCancelCountriesRuleRequestDialog(true);
    
    };
    const deleteSelectedCountries = () => {

        requestRuleUnblock(selectedRules, requestInfo,
            {'ref': toast, 'success': "Countries unblock request Submitted",
             "error": "Countries unblock request failed"});
        setdeleteCountriesRuleDialog(false);
        setSelectedRules(null);
    };

    const approveSelectedRulesRequest = () => {
        approveRequest(selectedRules, requestInfo,
            {'ref': toast, 'success': "Countries rule request approved",
             "error": "Countries rule request approval failed"});
        setApproveCountriesRuleRequestDialog(false);
        setSelectedRules(null);
        
    };

    const rejectSelectedRulesRequest = () => {
        rejectRequest(selectedRules, requestInfo,
            {'ref': toast, 'success': "Countries rule request rejected",
             "error": "Countries rule request rejection failed"});
        setRejectCountriesRuleRequestDialog(false);
        setSelectedRules(null);
       
    };

    const cancelSelectedRulesRequest = () => {
        if (!checkUserDeleteRequest(selectedRules, requestInfo)){
            toast.current.show({ severity: 'error', summary: 'Validation Failed', detail: 'Not authorized to Delete Other user request.', life: 3000 });
            return
        }
        deleteRequest(selectedRules, requestInfo,
            {'ref': toast, 'success': "Countries rule request deleted",
             "error": "Countries rule request deletion failed"});
        setCancelCountriesRuleRequestDialog(false);
        setSelectedRules(null);
    };


    const countryTableCRUD = () => {
        return (
            <div className="inline-block align-bottom ml-0.5">
                {
                    tableCrud.includes("Approve") &&
                    <Button label="Approve" icon="pi pi-trash" severity="success"
                        onClick={confirmApproveSelected} disabled={!selectedRules || !selectedRules.length ||  !userContext.hasOpsPrivelege} className="mx-1"/>
                }
                {
                    tableCrud.includes("Reject") &&
                    <Button label="Reject" icon="pi pi-times" severity="danger"
                        onClick={confirmRejectSelected} disabled={!selectedRules || !selectedRules.length ||  !userContext.hasOpsPrivelege} className="mx-1"/>
                }

                {
                    tableCrud.includes("Cancel") &&
                    <Button label="Delete" icon="pi pi-trash" severity="danger"
                        onClick={confirmCancelSelected} disabled={!selectedRules || !selectedRules.length ||  !userContext.hasOpsPrivelege} className="mx-1"/>
                }
                {/* <Button label="New" icon="pi pi-plus" severity="success" onClick={openNew} className="mx-1 " />
                <Button label="Edit" icon="pi pi-plus" severity="warning" onClick={openNew} className="mx-0.5"/> */}
                {
                    tableCrud.includes("Delete") &&
                    <Button label="Unblock" icon="pi pi-check-circle" severity="success"
                        onClick={confirmDeleteSelected} disabled={!selectedRules || !selectedRules.length} className="mx-1"/>
                }

            </div>
        );
    };

    const onCountryChange = (e, country) => {
        const val = (e.target && e.target.value) || '';
        let _countryRule = { ...countryRule };

        _countryRule[`${country}`] = val;

        setCountryRule(+countryRule);
    };

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    // Tracking filtered rows
    const [filteredCountries, setFilteredCountries] = useState([]);
    const countries = useMemo(() => {
        return rulesObj.map(row => (row['country']));}, [rulesObj]);
    
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilteredCountries(_filters);
        setGlobalFilterValue(value);
    };

    // Custom Filter Templates for Columns 

    const countryFilterTemplate = (options) => {
        return filterTemplate(options, "Country Picker", countries, true);
    };
    const serviceFilterTemplate = (options) => {

        return filterTemplate(options, "Service Picker", countryRuleContext.generalStatus.SupportedServices);
    };
    const ruleSourceFilterTemplate = (options) => {
        return filterTemplate(options, "Source Picker", countryRuleContext.generalStatus.SupportedSources.slice(1));
    };
    

    // Country Table column definitions

    const countryColumns = useMemo (() => {
        let tempCountryColumns = {...countryRuleTableSchema};
        tempCountryColumns['country']['filterElement'] = countryFilterTemplate;
        tempCountryColumns['service']['filterElement'] = serviceFilterTemplate;
        tempCountryColumns['blockStatus']['filterElement'] = blockStatusFilterTemplate
        if (props.tablePage === "ViewPage"){
            tempCountryColumns['blockStatus']['body'] = blockStatusBodyTemplate;
        }
        tempCountryColumns['requestState']['body'] = requestStatusBodyTemplate; 
        tempCountryColumns['region']['filterElement'] = blockRegionFilterTemplate;
        tempCountryColumns['ruleSource']['filterElement'] = ruleSourceFilterTemplate;
        return tempCountryColumns;
        // eslint-disable-next-line
    }, []);
    
    if (props.tablePage === "ViewPage"){
        delete countryColumns.requestState;
        delete countryColumns.requestTime;
        delete countryColumns.requestedByUser;
        delete countryColumns.requestedByUserName;
        delete countryColumns.lastUpdateTime;
        delete countryColumns.lastUpdatedByUser;
        delete countryColumns.lastUpdatedByUserName;
        delete countryColumns.requestDescription;
        delete countryColumns.approveDescription;
    }

    // Tracking column visibility
    const [visibleColumns, setVisibleColumns] = useState(() => {
        let defaultVisibleColumns = [];
        for (let columnField in countryColumns){
            if (!('defaultVisible' in countryColumns[columnField]) || (countryColumns[columnField]['defaultVisible'])){
                defaultVisibleColumns.push({'field': columnField, 'header': countryColumns[columnField]['header']});
            }
        }
        return defaultVisibleColumns;
    });

    // Populating Column picker options - ALL columns
    const columnPickerOptions = useMemo(() => {
        let tempColumnPickerOptions = [];
        for (let columnField in countryColumns){
            tempColumnPickerOptions.push({
                'header': countryColumns[columnField]['header'],'field': columnField
            });
            
        }
        return tempColumnPickerOptions;
        // eslint-disable-next-line
    }, []);


    // dataTable useRef
    const dt = useRef(null);


    const [filters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        country: { value: null, matchMode: FilterMatchMode.IN },
        region: { value: null, matchMode: FilterMatchMode.IN },
        service: { value: null, matchMode: FilterMatchMode.IN },
        blockStatus: { value: null, matchMode: FilterMatchMode.IN },
        ruleSource: { value: null, matchMode: FilterMatchMode.IN },
        ruleName: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        path: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        requestState: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        requestTime: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        requestedByUser: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        requestedByUserName: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        lastUpdateTime: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        lastUpdatedByUser: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        lastUpdatedByUserName: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        requestDescription: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        approveDescription: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]}
    });
    const [loading, setLoading] = useState(true);
    // Loading the Table rows
    useEffect(() => {
        setCountries(rulesObj);
        setFilteredCountries(rulesObj);
        setLoading(false);
    }, [countryRuleContext]); // eslint-disable-line react-hooks/exhaustive-deps

    const header = renderTableHeader(dt, filteredCountries, visibleColumns,  columnPickerOptions, 
        setVisibleColumns, globalFilterValue,onGlobalFilterChange, countryTableCRUD);
    // Rendering Table columns
    const tableColumn = renderTableColumn(countryColumns, visibleColumns, actionBodyTemplate)


    const countryRuleDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" onClick={saveCountryRule} />
        </React.Fragment>
    );
    const countryRuleViewDialogFooter = (
        <React.Fragment>
            <Button label="Close" icon="pi pi-times" outlined onClick={hideViewCountryRuleDialog} />
        </React.Fragment>
    );
    const deleteCountryRuleFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteCountryRuleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteCountryRule} />
        </React.Fragment>
    );
    const deleteCountriesRuleDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteCountriesRuleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteSelectedCountries} />
        </React.Fragment>
    );
    const rejectCountryRuleFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideRejectCountryRuleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={rejectCountryRuleRequest} />
        </React.Fragment>
    );
    const rejectCountriesRuleRequestDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideRejectCountriesRuleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={rejectSelectedRulesRequest} />
        </React.Fragment>
    );

    const approveCountryRuleFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideApproveCountryRuleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={approveCountryRuleRequest} />
        </React.Fragment>
    );
    const approveCountriesRuleRequestDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideApproveCountriesRuleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={approveSelectedRulesRequest} />
        </React.Fragment>
    );

    const cancelCountryRuleFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideCancelCountryRuleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={cancelCountryRuleRequest} />
        </React.Fragment>
    );
    const cancelCountriesRuleDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideCancelCountriesRuleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={cancelSelectedRulesRequest} />
        </React.Fragment>
    );


    return (
        <>
            <Toast ref={toast} />
            {Object.keys(rulesObj).length > 0 && 
                <DataTable  
                    scrollable scrollHeight="800px" 
                    isDataSelectable={isRowSelectable}
                    selection={selectedRules} onSelectionChange={(e) => setSelectedRules(e.value)}
                    tableStyle={{minWidth: "1200px"}} style={{'fontSize': '0.8em'}}
                    stripedRows
                    showGridlines 
                    value={Countries} 
                    ref={dt} 
                    paginator reorderableColumns
                    header={header} 
                    rows={10}
                    size="small"
                    loading={loading}
                    sortMode="multiple"
                    exportFilename='CountryBlockStatusReport'
                    // multiSortMeta={multiSortMeta}
                    onValueChange={filteredData => {setFilteredCountries(filteredData);}}
                    resizableColumns columnResizeMode="expand" 
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    rowsPerPageOptions={[10, 25, 50, 100, 1000]}
                    dataKey="ruleId" selectionMode="checkbox" 
                    filters={filters} filterDisplay="menu" 
                    emptyMessage="No Countries found." currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">   
                    {tableColumn}
                </DataTable>
            }

            <Dialog visible={countryRuleDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Country Rules" modal  footer={countryRuleDialogFooter} className="p-fluid" onHide={hideDialog}>
                <div className="field">
                    <label htmlFor="Country" className="font-bold">
                        Country
                    </label>
                    <InputText id="country" value={countryRule.country} onChange={(e) => onCountryChange(e, 'country')} required autoFocus className={classNames({ 'p-invalid': submitted && !countryRule.country })} />
                    {submitted && !countryRule.country && <small className="p-error">Name is required.</small>}
                </div>countryRUle
            </Dialog>
            <Dialog visible={viewCountryRuleDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Country Rule"
                modal footer={countryRuleViewDialogFooter} onHide={hideViewCountryRuleDialog}>
                    <div className="confirmation-content">
                        <RowTable rowData={countryRule} tableSchema={countryRuleTableSchema}/>
                    </div>
            </Dialog>
            {
               tableCrud.includes("Delete") && 
               <>
               <Dialog visible={deleteCountryRuleDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm"
            modal footer={deleteCountryRuleFooter} onHide={hideDeleteCountryRuleDialog}>
               <div className="confirmation-content">
                   <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                   {countryRule && (
                       <span>
                           Are you sure you want to delete <b>{countryRule.country}</b>?
                       </span>
                   )}
               </div>
           </Dialog>
            <Dialog visible={deleteCountriesRuleDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm"
            modal footer={deleteCountriesRuleDialogFooter} onHide={hideDeleteCountriesRuleDialog}>
               <div className="confirmation-content">
                   <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                   {countryRule && <span>Are you sure you want to Delete the Selected Rule?</span>}
               </div>
           </Dialog>
               </>


            }
            {
               tableCrud.includes("Cancel") && 
               <>

            <Dialog visible={cancelCountryRuleRequestDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm"
             modal footer={cancelCountryRuleFooter} onHide={hideCancelCountryRuleDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {countryRule && (
                        <span>
                            Are you sure you want to Delete the Request <b>{countryRule.ip} {countryRule.blockStatus}</b>?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={cancelCountriesRuleRequestDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm"
             modal footer={cancelCountriesRuleDialogFooter} onHide={hideCancelCountriesRuleDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {countryRule && <span>Are you sure you want to Delete the Selected Rule?</span>}
                </div>
            </Dialog>
            </>
}

{
               tableCrud.includes("Reject") && 
               <>

            <Dialog visible={rejectCountryRuleRequestDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm"
             modal footer={rejectCountryRuleFooter} onHide={hideRejectCountryRuleDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {countryRule && (
                        <span>
                            Are you sure you want to Reject <b>{countryRule.country} {countryRule.blockStatus}</b>?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={rejectCountriesRuleRequestDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm"
             modal footer={rejectCountriesRuleRequestDialogFooter} onHide={hideRejectCountriesRuleDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {countryRule && <span>Are you sure you want to Reject the Selected Rule?</span>}
                </div>
            </Dialog>
            </>
}
{
               tableCrud.includes("Approve") && 
               <>

            <Dialog visible={approveCountryRuleRequestDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm"
             modal footer={approveCountryRuleFooter} onHide={hideApproveCountryRuleDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {countryRule && (
                        <span>
                            Are you sure you want to Approve <b>{countryRule.country} {countryRule.blockStatus}</b>?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={approveCountriesRuleRequestDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm"
             modal footer={approveCountriesRuleRequestDialogFooter} onHide={hideApproveCountriesRuleDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {countryRule && <span>Are you sure you want to Approve the Selected Rule?</span>}
                </div>
            </Dialog>
            </>
}

        </>

    );
}