


import React from 'react';

import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import IconButton from '@mui/material/IconButton';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';

import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import AppConfig from '../appConfig.json'

import { ENV } from '../appConfig';
import { InputTextarea } from 'primereact/inputtextarea';
// import { RadioButton } from 'primereact/radiobutton';


const RequestStatusBodyColorTheme = {
    'Approved': "success",
    'Success': "success",
    'Rejected': "danger",
    'Failure': 'danger',
    'Requested': "info"
}

const RuleBlockStatusBodyColorTheme = {
    'Deny': "danger",
    'Block': "danger",
    'Disabled': "danger",
    'Allow': "success",
    'Enabled': "success"
}



// Populating Column filter options
export const getFilterOptions = (filterValues) => {
    let filterOptions = [];

    for (let i=0; i < filterValues.length; i++){
        // name - Display purpose
        // value - Value to be compared against Table cell value.
        filterOptions.push({'name': filterValues[i], 'value': filterValues[i]});
    }
    return filterOptions;
}

// Polulating Unique column filter options
export const getUniqueFilterOptions = (filterValues) => {
    let filterOptions = [...getFilterOptions(filterValues)];
    filterOptions = filterOptions.map(JSON.stringify);
    filterOptions = new Set(filterOptions);
    filterOptions = Array.from(filterOptions).map(JSON.parse);
    return filterOptions;
}


export const TablePreFilter = (rulesObj, preTableFilters, ruleContext) => {
    let tempRuleObj = [...rulesObj];
    let tablePreFilteredRows = [];
    // Filtering Selected KPI one by one, as criteria for each KPI cannot be clubbed.
    preTableFilters.forEach(preTableFilter => {
        let tempTablePreFilteredRows = ruleContext.kpiFilter.hasOwnProperty(preTableFilter) ? 
            ((typeof ruleContext.kpiFilter[preTableFilter] === "undefined" || 
                Object.keys(ruleContext.kpiFilter[preTableFilter]).length === 0 ) ? []: 
                ruleContext.filterTableRows(ruleContext.kpiFilter[preTableFilter], tempRuleObj)): tempRuleObj ;
        tablePreFilteredRows.push(...tempTablePreFilteredRows);
    });
    return Array.from(new Set(tablePreFilteredRows));
}



// Multi-Select Filter Template used for few of Columns
export const filterTemplate = (options, filterText, filterValues, isUniqueFilter=true) => {
    // TODO: GT, make dynamic filter
    return (
        <React.Fragment>
            <div className="mb-3 font-bold">{filterText}</div>
            <MultiSelect 
                filter
                maxSelectedLabels={1} 
                value={options.value} 
                options={options.filterOptions !== undefined ? options.filterOptions :
                        isUniqueFilter ? getUniqueFilterOptions(filterValues) : 
                        getFilterOptions(filterValues)}
                onChange={(e) => options.filterCallback(e.value)}
                optionLabel="name"
                placeholder="Any"
                className="p-column-filter" />
        </React.Fragment>
    );
};

// CSV file export
export const exportCSV = (dt, selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
};

// Excel file export
export const exportExcel = (filteredDevices, visibleColumns, fileName) => {
    import('xlsx').then((xlsx) => {
        // Formatting excel Data
        const excelData = () => {
            let tempExcelData = [];
            for (let i=0; i < filteredDevices.length;i++){
                let tempData = {};
                // Preserving and exporting only Visible Columns
                // Limitation : Column Re-ordering is not preserved in Excel export
                // TBD if that is important!
                // If required need to use onColReorder callback and track current Column order
                for(let j=0;j < visibleColumns.length; j++){
                    tempData[visibleColumns[j]['header']] = filteredDevices[i][visibleColumns[j]['field']];
                }
                tempExcelData.push(tempData);
            }
            return tempExcelData;
        }
        const worksheet = xlsx.utils.json_to_sheet(excelData());
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
        });

        saveAsExcelFile(excelBuffer, fileName);
    });
};

export const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then((module) => {
        if (module && module.default) {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            module.default.saveAs(data, fileName  + EXCEL_EXTENSION);
        }
    });
};

// Block Status Column Filter Template
export const blockStatusFilterTemplate = (options) => {
    return filterTemplate(options, "Status Picker", ['Deny', 'Allow']);
};

// Block Region Column Filter Template
export const blockRegionFilterTemplate = (options) => {
    return filterTemplate(options, "Region Picker", ["East", "West", "NA"]);
};

// Block Status Cell Body Template
export const blockStatusBodyTemplate = (rowData) => {
    return <Tag
             value={rowData.blockStatus} 
             severity={RuleBlockStatusBodyColorTheme[rowData.blockStatus]}
             className='font-normal' />;
};

// Request Status Cell Body Template
export const requestStatusBodyTemplate = (rowData) => {
    return <Tag
             value={rowData.requestState} 
             severity={RequestStatusBodyColorTheme[rowData.requestState]}
             className='font-normal' />;
};


export const isRowSelectable = (event) => {
    if(event.data){
        // Currently this logic is for Unblock and Approval Tables 
        // If Edit, New CRUD is introducted modify method accrodingly.
        return ((event.data.blockStatus === "Deny" || event.data.blockStatus === "Disabled" ) && (event.data.requestState ===  undefined ||
        (event.data.requestState !== "Approved" && event.data.requestState !== "Success")));
    }
    else {
        return true;
    }

}

// Rendering Table Header
export const renderTableHeader = (dt, filteredDevices, visibleColumns,
      columnPickerOptions, setVisibleColumns, globalFilterValue, onGlobalFilterChange, TableCRUD) => {
    const iconColor = 'text-white';
    const iconFont = {'fontSize': 25};
    
    // Updating selected Columns for Display
    const onColumnSelectionToggle = (event) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = columnPickerOptions.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));
        setVisibleColumns(orderedSelectedColumns);
    };


    return (
        <div className="w-full grow shrink flex flex-wrap text-center justify-between sm:flex-auto sticky">
            <div>
                                {/* Column Picker */}
                                <MultiSelect id='columnPicker' 
                    style={{height: '3rem'}}
                    filter
                    panelStyle={{ width: '18rem'}}
                    scrollHeight='380px'
                    fixedPlaceholder
                    maxSelectedLabels={1} 
                    value={visibleColumns}
                    options={columnPickerOptions}
                    optionLabel="header"
                    onChange={onColumnSelectionToggle}
                    placeholder={"Columns"}
                />
                
                {TableCRUD && <TableCRUD />}
            </div>



            <div><InputText value={globalFilterValue} onChange={onGlobalFilterChange} style={{height: '3rem'}} placeholder="Keyword Search" /></div>
            <div className=' mt-1'> 
                <Button onClick={() => dt.current.reset()}
                        className={`hover:text-white text-blue-700 font-extrabold border-transparent dark:border-white mx-1` } 
                        variant='contained'> 
                    <FilterAltOffOutlinedIcon />Reset</Button>
                {/* Export Options */}
                <Tooltip title="Export CSV" arrow placement="top-end">
                    <IconButton  className={iconColor} onClick={() => exportCSV(dt, false)} data-pr-tooltip="CSV"> 
                        <span style={iconFont} className="p-button-icon p-c pi pi-file mx-0.5 text-blue-700"></span>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Export Excel" arrow placement="top-end">
                    <IconButton className={iconColor} onClick={() => exportExcel(filteredDevices, visibleColumns, dt.current.props.exportFilename)} data-pr-tooltip="XLS"> 
                        <span style={iconFont}  className="p-button-icon p-c pi pi-file-excel text-blue-700 mx-0.5"></span>
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    );
};



// Rendering Table Column
export const renderTableColumn = (deviceColumns, visibleColumns, actionBodyTemplate=null) => {
    let ColumnJSX = [<Column selectionMode="multiple" exportable={false}></Column>];
    ColumnJSX.push(...visibleColumns.map((columnData) => (
        <Column 
            key={columnData.field}
            field={columnData.field} 
            columnKey={columnData.field}
            filterField={columnData.field}
            style={{ minWidth: '200px' }}
            {...deviceColumns[columnData.field]}
            sortable
            filter
            // filterPlaceholder is not applicable for Columns with filterElement
            filterPlaceholder={deviceColumns[columnData.field]['header']}
            >
        </Column>

    )));
    if (actionBodyTemplate){
        ColumnJSX.push(<Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '1rem' }}></Column>)
    }
    
    return ColumnJSX;
}

// Description Div for Confirmation Dialog of Table.
export const RenderRequestDescriptionDiv = ({description, setRequestDescription}) => {
    return (
        <div className="my-2">
        <label htmlFor="description" className="font-bold mb-3">
                Description
            </label>
            <InputTextarea className="block" id="description" value={description} 
                onChange={(e) => setRequestDescription((e.target && e.target.value) || '')} required rows={3} cols={55} />
    </div>
    )
}


// Validate if user has permission to Delete Rule Request.
export const checkUserDeleteRequest = (payload, requestInfo) => {
    let isUserRequestValid = true;
    payload.every(function(ruleItem) {
        if (ruleItem.requestedByUserName !== requestInfo.username){
            isUserRequestValid = false;
            return false;
        }
        return true;
    });
    return isUserRequestValid;
}

// Backend API call for Requesting Rule Unblock
export const requestRuleUnblock = (payload, requestInfo, toastObj) => {
    const requestPayload = {'requestItems': payload, 'requestInfo': requestInfo};
    const requestPayloadJsonString = JSON.stringify(requestPayload);
    fetch(AppConfig[`unblockRule${ENV}Url`], {
        method: 'POST',
        // mode: 'no-cors',
        body: requestPayloadJsonString,
      })
    .then((result) => {
        if(result.ok){
            fetch(AppConfig[`notifyRuleRequest${ENV}Url`], {
                method: 'POST',
                // mode: 'no-cors',
                body: requestPayloadJsonString
              }).then((result) => {
                if(result.ok){
                    toastObj.ref.current.show({ severity: 'success', summary: 'Successful', detail: toastObj.success, life: 3000 });
                }
                else {
                    toastObj.ref.current.show({ severity: 'error', summary: 'Request Failed', detail: toastObj.error, life: 3000 });
                }
              })
        }
        else{
            toastObj.ref.current.show({ severity: 'error', summary: 'Request Failed', detail: toastObj.error, life: 3000 });
        }
      })
      .catch(function(error){
        console.log("Failed to Request Unblock Rule" + error);
        toastObj.ref.current.show({ severity: 'error', summary: 'Request Failed', detail: toastObj.detail, life: 3000 });
      })
}

// Backend API call for Approving Rule Unblock Request
export const approveRequest = (payload, requestInfo, toastObj) => {
    const requestPayload = {'requestItems': payload, 'requestInfo': requestInfo};
    const requestPayloadJsonString = JSON.stringify(requestPayload);
    fetch(AppConfig[`unblockRuleApprove${ENV}Url`], {
        method: 'POST',
        // mode: 'no-cors',
        body: requestPayloadJsonString
      }).then((result) =>{
        
        if(result.ok){
            fetch(AppConfig[`approveRuleRequest${ENV}Url`], {
                method: 'POST',
                // mode: 'no-cors',
                body: requestPayloadJsonString
              }).then((result) => {
                if(result.ok){
                    toastObj.ref.current.show({ severity: 'success', summary: 'Successful', detail: toastObj.success, life: 3000 });
                }
                else{
                    toastObj.ref.current.show({ severity: 'error', summary: 'Request Failed', detail: toastObj.error, life: 3000 });
                }
              })
        }
        else{
            toastObj.ref.current.show({ severity: 'error', summary: 'Request Failed', detail: toastObj.error, life: 3000 });
        }

      }
      )
      .catch(function(error){
        console.log("Failed to Approve Unblock Rule Request" + error);
        toastObj.ref.current.show({ severity: 'error', summary: 'Request Failed', detail: toastObj.detail, life: 3000 });
      })

}


// Backend API call for Rejecting Rule Unblock Request
export const rejectRequest = (payload, requestInfo, toastObj) => {
    const requestPayload = {'requestItems': payload, 'requestInfo': requestInfo};
    fetch(AppConfig[`rejectRuleRequest${ENV}Url`], {
        method: 'POST',
        // mode: 'no-cors',
        body: JSON.stringify(requestPayload)
      })
      .then((result) => {
        if(result.ok){
            toastObj.ref.current.show({ severity: 'success', summary: 'Successful', detail: toastObj.success, life: 3000 });
        }
        else{
            toastObj.ref.current.show({ severity: 'error', summary: 'Request Failed', detail: toastObj.error, life: 3000 });
        }
      })
      .catch(function(error){
        console.log("Failed to Reject Unblock Rule Request" + error);
        toastObj.ref.current.show({ severity: 'error', summary: 'Request Failed', detail: toastObj.detail, life: 3000 });
      })

}

// Backend API call for Deleting Rule Unblock Request
export const deleteRequest = (payload, requestInfo, toastObj) => {
    const requestPayload = {'requestItems': payload, 'requestInfo': requestInfo};
    fetch(AppConfig[`deleteRuleRequest${ENV}Url`], {
        method: 'POST',
        // mode: 'no-cors',
        body: JSON.stringify(requestPayload)
      }).then((result) => {
        if(result.ok){
            toastObj.ref.current.show({ severity: 'success', summary: 'Successful', detail: toastObj.success, life: 3000 });
        }
        else{
            toastObj.ref.current.show({ severity: 'error', summary: 'Request Failed', detail: toastObj.error, life: 3000 });
        }
      })
      .catch(function(error){
        console.log("Failed to Delete Unblock Rule Request" + error);
        toastObj.ref.current.show({ severity: 'error', summary: 'Request Failed', detail: toastObj.detail, life: 3000 });
      })
}