import { createContext } from "react";


// Context used in the block status tables
export const RulesContext = createContext();
// Context used in block status request tables.
export const RulesRequestContext = createContext();

export const UserContext = createContext();

