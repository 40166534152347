import React  from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

// Table to present Row Data in View Dialog
export default function RowTable(props) {

    const rowData = {...props.rowData};
    // Not presenting Rule ID
    if(rowData.hasOwnProperty('ruleId')){
        delete rowData.ruleId
    }
    const rowDataObj = [];
    // Presenting Row Details with same schema for the Table.
    Object.keys(props.tableSchema).forEach(rowField => {
        if(rowData.hasOwnProperty(rowField)){
            rowDataObj.push({'field': props.tableSchema[rowField]['header'], 'value': rowData[rowField]})
        }
        
    });
    // Translating Row to Table with columns: field, value

    return (
            <DataTable className="m-2" value={rowDataObj} tableStyle={{ minWidth: '25rem' }} size='small' stripedRows 
                paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} resizableColumns  showGridlines 
                 columnResizeMode="expand" style={{'fontSize': '0.8em'}}  >
                <Column field="field"  header="Field"></Column>
                <Column field="value"  header="Value"></Column>
            </DataTable>

    );
}
        