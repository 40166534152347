import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

import './index.css';
import AppConfig from '../appConfig.json'
import { Loading } from "../ui-components/LoadingAnimation";

import { RulesContext, UserContext } from "../ui-components/ContextManager.jsx";
import { TablePreFilterConfig } from "../utils/layout_util";
import { useMsal } from "@azure/msal-react";
import { checkRequiredGroupPresent } from "../auth/RouteGuard";
import { groups } from "../auth/AuthConfig";
import { ENV } from "../appConfig";


// Main Layout
export function BlockStatusReport(ReportJSX) {
    const { instance } = useMsal();
    const url = AppConfig[`blockStatusReader${ENV}Url`];
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [blockRules, setblockRules] = useState({});
    const kpiSelectInit = {}
    const [selectedKpi, setSelectedKpi] = useState(kpiSelectInit);


    const updateFilter = (prevFilter, currFilter) => {
        let updatedFilter = {};
        if ((typeof prevFilter === "object") &&
                 (('service' in prevFilter) &&  (prevFilter['service'] === currFilter['service']))){
            if (JSON.stringify(prevFilter) !== JSON.stringify(currFilter)){
                updatedFilter = currFilter;
            }
        }
        else {
            updatedFilter = currFilter;
        }
        return updatedFilter;
       
    }


    const filterOperators = {
        '===': function(a, b){ return a === b},
        '!==': function(a, b){ return a !== b},
        '>': function(a, b){ return a > b},
        '>=': function(a, b){ return a >= b},
        'in': function(a,b){return b.includes(a);}
      }

    const checkFilterCriteria = (value, filterElement) => {
    return filterOperators[filterElement['operator']](value, filterElement['value']);
    }

    const filterTableRows = (filter, tableRows) => {
        const filterKeys = Object.keys(filter);
        return tableRows.filter((tableRow) => {
            let filterFlag = true;
            [...filterKeys].every(element => {
                if (typeof filter[element] === 'object'){
                    filterFlag = (filterFlag) && checkFilterCriteria(tableRow[element], filter[element]);
                }
                else {
                    filterFlag = (filterFlag) && tableRow[element] === filter[element];
                }
                
                return true
            });
            return filterFlag;
        });

    }

    // const mergeFilter = (filterCategories) => {
    //     let mergedFilter = {};
    //     filterCategories.forEach(filterCategory => {
    //         console.log(filterCategory)
    //         if (typeof filterCategory === "object"){
    //         for (let filterAttr in filterCategory){
    //             if (!(filterAttr in mergedFilter)){
    //                 mergedFilter[filterAttr] = {'value': [filterCategory[filterAttr]], 'operator': "in"};
    //             }
    //             else {
    //                 mergedFilter[filterAttr]['value'].push(filterCategory[filterAttr]);
    //             }
    //         }
    //     }});

        
    //     return mergedFilter;
    // }

    const UpdateSelectedKpiFilter = ({
        XiOIp=null,
        XiOEastIp=null,
        XiOWestIp=null,
        XiOCountry=null,
        XiOEastCountry=null,
        XiOWestCountry=null,
        XiODevice=null,
        XiOEastDevice=null,
        XiOWestDevice=null,
        WeatherAllowIp=null,
        WeatherDenyIp=null,
        FirmwareDownloadDenyIp=null,
        FirmwareDownloadAllowIp=null,
        FirmwareDownloadAllowIp_Path=null,
        FirmwareDownloadDenyIp_Path=null,
        FirmwareDownloadCountry=null,
      }) => {

      const updatedCategoryKpiSelection = {
        // 'Ip': Ip !== null ? updateFilter(selectedKpi['Ip'], Ip): selectedKpi['Ip'],
        // 'Country': Country !== null ? updateFilter(selectedKpi['Country'], Country): selectedKpi['Country'],
        // 'Device': Device !== null ? updateFilter(selectedKpi['Device'], Device): selectedKpi['Device'],
        'XiOIp': XiOIp !== null ? 
            updateFilter(selectedKpi['XiOIp'], XiOIp): selectedKpi['XiOIp'],
        'XiOEastIp': XiOEastIp !== null ? 
        updateFilter(selectedKpi['XiOEastIp'], XiOEastIp): selectedKpi['XiOEastIp'],
        'XiOWestIp': XiOWestIp !== null ? 
            updateFilter(selectedKpi['XiOWestIp'], XiOWestIp): selectedKpi['XiOWestIp'],
        'XiOCountry': XiOCountry !== null ? 
            updateFilter(selectedKpi['XiOCountry'], XiOCountry): selectedKpi['XiOCountry'],
        'XiOEastCountry': XiOEastCountry !== null ? 
            updateFilter(selectedKpi['XiOEastCountry'], XiOEastCountry): selectedKpi['XiOEastCountry'],
        'XiOWestCountry': XiOWestCountry !== null ? 
            updateFilter(selectedKpi['XiOWestCountry'], XiOWestCountry): selectedKpi['XiOWestCountry'],
        'XiODevice': XiODevice !== null ? 
            updateFilter(selectedKpi['XiODevice'], XiODevice): selectedKpi['XiODevice'],
        'XiOEastDevice': XiOEastDevice !== null ? 
            updateFilter(selectedKpi['XiOEastDevice'], XiOEastDevice): selectedKpi['XiOEastDevice'],
        'XiOWestDevice': XiOWestDevice !== null ? 
            updateFilter(selectedKpi['XiOWestDevice'], XiOWestDevice): selectedKpi['XiOWestDevice'],
        'WeatherAllowIp': WeatherAllowIp !== null ? 
            updateFilter(selectedKpi['WeatherAllowIp'], WeatherAllowIp): selectedKpi['WeatherAllowIp'],
        'WeatherDenyIp': WeatherDenyIp !== null ? 
            updateFilter(selectedKpi['WeatherDenyIp'], WeatherDenyIp): selectedKpi['WeatherDenyIp'],
        'FirmwareDownloadDenyIp': FirmwareDownloadDenyIp !== null ? 
            updateFilter(selectedKpi['FirmwareDownloadDenyIp'], FirmwareDownloadDenyIp): selectedKpi['FirmwareDownloadDenyIp'],
        'FirmwareDownloadAllowIp': FirmwareDownloadAllowIp !== null ? 
            updateFilter(selectedKpi['FirmwareDownloadAllowIp'], FirmwareDownloadAllowIp): selectedKpi['FirmwareDownloadAllowIp'],
        'FirmwareDownloadAllowIp_Path': FirmwareDownloadAllowIp_Path !== null ? 
            updateFilter(selectedKpi['FirmwareDownloadAllowIp_Path'], FirmwareDownloadAllowIp_Path): selectedKpi['FirmwareDownloadAllowIp_Path'],
        'FirmwareDownloadDenyIp_Path': FirmwareDownloadDenyIp_Path !== null ? 
            updateFilter(selectedKpi['FirmwareDownloadDenyIp_Path'], FirmwareDownloadDenyIp_Path): selectedKpi['FirmwareDownloadDenyIp_Path'],
        'FirmwareDownloadCountry': FirmwareDownloadCountry !== null ? 
            updateFilter(selectedKpi['FirmwareDownloadCountry'], FirmwareDownloadCountry): selectedKpi['FirmwareDownloadCountry'],
      }

      
      const isKpiSelectedCheck = (kpiNames) => {
        let isKpiSelectedFlag = false;
        kpiNames.forEach(kpiFilterName => {
                isKpiSelectedFlag = isKpiSelectedFlag || ((typeof updatedCategoryKpiSelection[kpiFilterName] !== 'undefined')
                    && Object.keys(updatedCategoryKpiSelection[kpiFilterName]).length > 0);
                if(isKpiSelectedFlag){
                    return isKpiSelectedFlag;
                }
      });
      return isKpiSelectedFlag;
    }
      let updatedKpiSelection = {'Ip': isKpiSelectedCheck(TablePreFilterConfig.Ip), 
            'Device': isKpiSelectedCheck(TablePreFilterConfig.Device),
             'Country': isKpiSelectedCheck(TablePreFilterConfig.Country)};

            let isKpiSelect = false;
            for (let category in updatedKpiSelection){
            if(updatedKpiSelection[category]){
                isKpiSelect = true;
                break;
            }
            }
        updatedKpiSelection = {...updatedKpiSelection, ...updatedCategoryKpiSelection};
      if (isKpiSelect){
        setSelectedKpi(updatedKpiSelection);  
      }
      else {
        setSelectedKpi({});
      }
    }

    const setBlockStatusContext = (kpiFilter) => {
        let ipRules = [ ...blockRules.Akamai.IPs, 
            ...blockRules.Weather.IPs, 
            ...blockRules.XiO.WAFIPs]
        let countryRules = [...blockRules.XiO.WAFCountries, ...blockRules.Akamai.Countries, ...blockRules.Weather.Countries];
        let deviceRules = [...blockRules.XiO.eastIoTDenyDevices, ...blockRules.XiO.westIoTDenyDevices];
        let isSelectedKpiEmpty = Object.keys(kpiFilter).length === 0;
        return  {
            ipRules: ipRules.map((rule, ruleIndex) => ({...rule, id: ruleIndex})),
            countryRules: countryRules.map((rule, ruleIndex) => ({...rule, id: ruleIndex})),
            deviceRules: deviceRules.map((rule, ruleIndex) => ({...rule, id: ruleIndex})),
            eastIoT: blockRules.XiO.eastIoTDenyDevices.map((rule, ruleIndex) => ({...rule, id: ruleIndex})),
            westIoT: blockRules.XiO.westIoTDenyDevices.map((rule, ruleIndex) => ({...rule, id: ruleIndex})),
            akamai: blockRules.Akamai,
            generalStatus: blockRules.GeneralStatus,
            xio: blockRules.XiO,
            weather: blockRules.Weather,
            kpiFilter: kpiFilter,
            UpdateSelectedKpiFilter: UpdateSelectedKpiFilter,
            filterTableRows: filterTableRows,
            displayIpTable: isSelectedKpiEmpty || kpiFilter['Ip'] ,
            displayCountryTable: isSelectedKpiEmpty || kpiFilter['Country'],
            displayDeviceTable: isSelectedKpiEmpty || kpiFilter['Device'],
        }
    }

    useEffect(() => {
        // On Re-render skip query as Data is same.
        if (Object.keys(blockRules).length > 0){
            return;
        }
        fetch(url)
          .then(res => res.json())
          .catch(function(error){
            console.log("Failed to get Block Status Report" + error);
          })
          .then(
            (result) => {
                setIsLoaded(true);
                setblockRules(result);

            },
            (error) => {
                setIsLoaded(true);
                setError(error);
            }
          )
          // eslint-disable-next-line
        }, []);

    // useEffect(()=>{
    //     console.log("Selected KPI change deted");
    // }, [selectedKpi])
    
    
      if (error) { return <div className="text-header-txt-d text-center">Error: {error.message}</div>; }
      else if (!isLoaded) return <Loading />;
      else {
        const activeAccount = instance.getActiveAccount();
        try{
            return (
                <>
                <Grid container justifyContent="center">
                    <AuthenticatedTemplate>
                        {/* Master Context used to pass information to child components */}
                        <UserContext.Provider
                            value={{...activeAccount, ...{"hasOpsPrivelege": checkRequiredGroupPresent([groups.EsOps], activeAccount)}}}>
                            <RulesContext.Provider
                                value={setBlockStatusContext(selectedKpi)
                                } >
                                {/* Rending main layout */}
                                <div className=" overflow-auto p-6 grow shrink  dark:bg-main-bg-d text-main-txt-l dark:text-main-txt-d -mt-8  main-layout">   
                                    <ReportJSX/>
                                    <div className="inline-block  text-white  font-thin ">Report Last Updated : {blockRules.GeneralStatus.ReportLastUpdate}</div>
                                </div>
                                
                            </RulesContext.Provider>
                        </UserContext.Provider>
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <center>Sign in to view Blocked Devices, IP & Country Report</center>
                    </UnauthenticatedTemplate>
                </Grid>
                </>
            );
        }
        catch (error) {
            console.log(error)
            return (<><div className="text-red-400  text-center text-2xl">Failed to get Block Status Report</div></>)
        }

    }
}

