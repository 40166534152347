import { LogLevel } from "@azure/msal-browser";
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito
const REACT_APP_AZURE_AD_CLIENTID = process.env.REACT_APP_AZURE_AD_CLIENTID;
const REACT_APP_AZURE_AD_TENANTID = process.env.REACT_APP_AZURE_AD_TENANTID;
// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: REACT_APP_AZURE_AD_CLIENTID,
        authority: "https://login.microsoftonline.com/" + REACT_APP_AZURE_AD_TENANTID,
        // Redirect to origin URI
        redirectUri: window.location.origin,
        postLogoutRedirectUri: "/"
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: isIE || isEdge || isFirefox
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {	
                    return;	
                }
                switch (level) {	
                    case LogLevel.Error:	
                        console.error(message);	
                        return;	
                    // case LogLevel.Info:	
                    //     console.info(message);	
                    //     return;	
                    // case LogLevel.Verbose:	
                    //     console.debug(message);	
                    //     return;	
                    // case LogLevel.Warning:	
                    //     console.warn(message);	
                    //     return;	
                    default:
                        return;
                }
            }
        }
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: [`api://${REACT_APP_AZURE_AD_CLIENTID}/access_via_group_assignments`]
};

// Group IDs to allow Authorization.
export const groups = {
    EsOps: '1d2e375c-6310-4d18-9d2d-58a29ca063af',
    CrestronSupportGroup: 'c1a3f0d2-5f3a-4d7c-9c58-974c0d76c1aa',
};

/**
 * IMPORTANT: In case of overage, group list is cached for 1 hr by default, and thus cached groups 
 * will miss any changes to a users group membership for this duration. For capturing real-time 
 * changes to a user's group membership, consider implementing Microsoft Graph change notifications. 
 * For more information, visit: https://learn.microsoft.com/graph/api/resources/webhooks
 */
export const CACHE_TTL_IN_MS = 60 * 60 * 1000; // 1 hour in milliseconds