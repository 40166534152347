import React, { useContext } from "react";

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import { Accordion, AccordionTab } from 'primereact/accordion';

import { RulesContext } from "../../ui-components/ContextManager";

import { IPRuleTable } from "../tables/IpRulesTable";
import { CountryRuleTable } from "../tables/CountryRulesTable";
import { IoTRuleTable } from "../tables/IotRulesTable";

import { RenderSubKPI, TableKpiFilterConfig, AccordionHeaderDiv } from "../../utils/layout_util";

import { BlockStatusReport } from "../BlockStatus";

// XIO KPIs

function XiOKPI1({rulesContext}) {
  return (
    <div className="flex flex-wrap justify-center gap-x-2 gap-y-1 text-1xl">
        <RenderSubKPI context={rulesContext} kpiText="Disabled Devices"
         kpiValue={rulesContext.generalStatus.totalXiODenyDevicesCount} 
         kpiIconColour="red" kpiIcon="pi-shield"
         category={TableKpiFilterConfig.XiODevice.category}
         filterValue={TableKpiFilterConfig.XiODevice.filterValue}/>
        <RenderSubKPI context={rulesContext} kpiText="Blocked Countries" 
         kpiValue={rulesContext.generalStatus.totalXiODenyCountriesCount} 
         kpiIconColour="red" kpiIcon="pi-shield"
         category={TableKpiFilterConfig.XiOCountry.category} 
         filterValue={TableKpiFilterConfig.XiOCountry.filterValue}/>
    </div>
  )
}

function XiOKPI2({rulesContext}) {  
    return (
      <div className="flex flex-wrap justify-center gap-x-2 gap-y-1 text-1xl">
          <RenderSubKPI context={rulesContext} kpiText="Allow IP" 
            kpiValue={rulesContext.generalStatus.totalXiOAllowedIPsCount}
            kpiIconColour="green" kpiIcon="pi-shield"
            category={TableKpiFilterConfig.XiOIp.category} 
            filterValue={TableKpiFilterConfig.XiOIp.filterValue}/>
      </div>
    )
  }

// Firmware Download KPIs

function AkamaiKPI1({rulesContext}) {  
    return (
      <div className="flex flex-wrap justify-center gap-x-2 gap-y-1 text-1xl ">
          <RenderSubKPI context={rulesContext} kpiText="Deny IP&emsp;&emsp;&emsp;&emsp;" 
              kpiValue={rulesContext.generalStatus.totalAkamaiDenyIPsCount} 
              kpiIconColour="red" kpiIcon="pi-shield"
              category={TableKpiFilterConfig.FirmwareDownloadDenyIp.category}
              filterValue={TableKpiFilterConfig.FirmwareDownloadDenyIp.filterValue}/>
          <RenderSubKPI context={rulesContext} kpiText="Deny IP & Path" 
              kpiValue={rulesContext.generalStatus['totalAkamaiDenyPath+IPsCount']} 
              kpiIconColour="red" kpiIcon="pi-shield"
              category={TableKpiFilterConfig.FirmwareDownloadDenyIp_Path.category}
              filterValue={TableKpiFilterConfig.FirmwareDownloadDenyIp_Path.filterValue}/>
          <RenderSubKPI context={rulesContext} kpiText="Blocked Countries"
              kpiValue={rulesContext.generalStatus.totalAkamaiDenyCountriesCount}
              kpiIconColour="red" kpiIcon="pi-shield"
              category={TableKpiFilterConfig.FirmwareDownloadCountry.category}
              filterValue={TableKpiFilterConfig.FirmwareDownloadCountry.filterValue}/>
      </div>
    )
}

  
function AkamaiKPI2({rulesContext}) {
    return (
      <div className="flex flex-wrap grow strech justify-center gap-x-2 gap-y-1 text-1xl ">
          <RenderSubKPI context={rulesContext} kpiText="Allow IP&emsp;&emsp;&emsp;" 
            kpiValue={rulesContext.generalStatus['totalAkamaiAllowIPsCount']}
            kpiIconColour="green" kpiIcon="pi-shield"
            category={TableKpiFilterConfig.FirmwareDownloadAllowIp.category} 
            filterValue={TableKpiFilterConfig.FirmwareDownloadAllowIp.filterValue}/>
          <RenderSubKPI context={rulesContext} kpiText="Allow IP & Path" 
              kpiValue={rulesContext.generalStatus['totalAkamaiAllowPath+IPsCount']}
              kpiIconColour="green" kpiIcon="pi-shield"
              category={TableKpiFilterConfig.FirmwareDownloadAllowIp_Path.category} 
              filterValue={TableKpiFilterConfig.FirmwareDownloadAllowIp_Path.filterValue}/>
      </div>
    )
}

// Weather KPIs

function WeatherKPI1({rulesContext}) {
    return (
        <div className="flex flex-wrap justify-center gap-x-2 gap-y-1 text-1xl ">
            <RenderSubKPI context={rulesContext} kpiText="Deny IP" 
                kpiValue={rulesContext.generalStatus.totalWeatherDenyIPsCount}
                kpiIconColour="red" kpiIcon="pi-shield"
                category={TableKpiFilterConfig.WeatherDenyIp.category}
                 filterValue={TableKpiFilterConfig.WeatherDenyIp.filterValue}/>
        </div>
    )
  }
  

function WeatherKPI2({rulesContext}) {  
  return (
      <div className="flex flex-wrap justify-center gap-x-2 gap-y-1 text-1xl ">
          <RenderSubKPI context={rulesContext} kpiText="Allow IP" 
              kpiValue={rulesContext.generalStatus.totalWeatherAllowedIPsCount}
              kpiIconColour="green" kpiIcon="pi-shield"
              category={TableKpiFilterConfig.WeatherAllowIp.category}
              filterValue={TableKpiFilterConfig.WeatherAllowIp.filterValue}/>
      </div>  
  )
}

// Rendering KPI layout
function KPILayout(){
    const rulesContext = useContext(RulesContext);
    return (

        <div className="flex flex-wrap justify-center gap-x-1.5 gap-y-1  text-1xl">

            <div className="bubs-dynamic">
                    <div className="bubs-group-kpi">
                        <span className="block text-kpi-group-header  mb-1 text-center">
                        <i className={`pi pi-cloud  text-xl px-2 inline`}></i>XiO
                        </span>
                        <XiOKPI1 rulesContext={rulesContext}/>
                        <XiOKPI2 rulesContext={rulesContext}/>
                    </div>
            </div> 
            <div className="bubs-dynamic">

                    <div className="bubs-group-kpi">
                        <span className="block text-kpi-group-header mb-1 text-center">
                        <i className={`pi pi-bolt  text-xl px-2 inline`}></i>Weather</span>
                        <WeatherKPI1 rulesContext={rulesContext}/>
                        <WeatherKPI2 rulesContext={rulesContext}/>
                    </div>
            </div> 
            <div className="bubs-dynamic">
                <div className="bubs-group-kpi">
                    <span className="block text-kpi-group-header  mb-1 text-center">
                    <i className={`pi pi-download  text-xl px-2 inline`}></i>Firmware Download</span>
                    <AkamaiKPI1 rulesContext={rulesContext}/>
                    <AkamaiKPI2 rulesContext={rulesContext}/>
                </div>
            </div> 
        </div>

    )
}

// Rendering Dashboard Components
function RenderDashboard() {
  const ruleContext = useContext(RulesContext);

    return (
      <>
        <div >
            <KPILayout/>
            <Accordion multiple  className="w-full overflow-auto mt-3"> 

              {ruleContext.displayDeviceTable  && 
                <AccordionTab header={AccordionHeaderDiv("Status by Device", ruleContext.generalStatus.IoTRegistryReadInterval)}>
                  <IoTRuleTable tableName="Device Table" iotRuleContext={ruleContext}
                    tableCrud={['Delete']} tablePage={"ViewPage"}/>
                </AccordionTab>}
              {ruleContext.displayIpTable  && 
                <AccordionTab header={AccordionHeaderDiv("Status by IP",
                     `NSG=${ruleContext.generalStatus.NSGReadInterval}, Akamai=${ruleContext.generalStatus.AkamaiAutomationReadInternal}`)}>
                  <IPRuleTable tableName="IP Table" ipRuleContext={ruleContext}
                   tableCrud={['Delete']} tablePage={"ViewPage"} page={"Dashboard"}/>
                </AccordionTab>}
              {ruleContext.displayCountryTable && <AccordionTab
                 header={AccordionHeaderDiv("Status by Country", 
                    `WAF=${ruleContext.generalStatus.WAFReadInterval}, Akamai=${ruleContext.generalStatus.AkamaiAutomationReadInternal}`)}>
                    <CountryRuleTable tableName="Country Table" countryRuleContext={ruleContext}
                     tableCrud={['Delete']} tablePage={"ViewPage"}/>
                </AccordionTab>}

          </Accordion>
        </div>
      </>
    )
  }
  
// Embedding  Rendered Dashboard to Main layout

export function Dashboard() {
    return BlockStatusReport(RenderDashboard)
}

