import React from 'react';

import { Button } from 'primereact/button';


// Side Bar Menu definitions

export const HomeMenu = () => {

    // Menu item definitions
    const HomeMenuItem = [
        { label: 'Dashboard', icon: 'pi  pi-fw pi-home', href: "../" }
    ];

    return (
        MenuLayoutRender(HomeMenuItem)
    );
};


export const ReportMenu = () => {

    // Menu item definitions
    const AppMenuItem = [
        { label: 'XiO', icon: 'pi pi-fw pi-cloud', href: "../XiOBlockStatusReport" },
        { label: 'Weather', icon: 'pi pi-fw pi-bolt', href: "../WeatherBlockStatusReport" },
        { label: 'Firmware Download', icon: 'pi pi-fw pi-download', href: "../FirmwareDownloadBlockStatusReport" }
    ];

    return (
        MenuLayoutRender(AppMenuItem)
    );
};

export const RequestMenu = () => {

    // Menu item definitions
    const RequestMenuItem = [
        { label: 'Request', icon: 'pi  pi-fw pi-table', href: "../Request" }
    ];

    return (
        MenuLayoutRender(RequestMenuItem)
    );
};

function MenuLayoutRender(MenuItem){
    return (
        <ul className="layout-menu">
        {
            MenuItem.map((item, i) => {
                return <Button className="bg-transparent border-transparent block" key={`${item.label}`}>
                    <a  href={`${item.href}`}>
                        <div className=' text-gray-600 text-lg' >
                            <i className={`${item.icon}`}></i>
                            <div className="px-2 inline" >{item.label}</div>
                        </div>
                    </a></Button>
            })
        }
    </ul>
    )
}
