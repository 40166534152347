import React, { useState, useMemo, useRef, useEffect, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';

import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';



import { TablePreFilterConfig } from "../../utils/layout_util";
import { renderTableColumn, renderTableHeader,
     blockRegionFilterTemplate, filterTemplate, 
     blockStatusFilterTemplate, blockStatusBodyTemplate, TablePreFilter, requestRuleUnblock, 
     approveRequest, deleteRequest, rejectRequest, isRowSelectable, 
     checkUserDeleteRequest, RenderRequestDescriptionDiv, requestStatusBodyTemplate } from "../../lib/Table";

import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { UserContext } from "../../ui-components/ContextManager";
import { ipRuleTableSchema } from "./IpRulesTableSchema";
import RowTable from "../../lib/RowTable";

// IP Block status table
export function IPRuleTable(props) {
    const userContext = useContext(UserContext);
    const [requestDescription, setRequestDescription] = useState(""); 
    const requestInfo = {'username': userContext.username, 'name' : userContext.name};
    const ipRuleContext = props.ipRuleContext;
    let rulesObj =  props.rulesObj === undefined ? ipRuleContext.ipRules : props.rulesObj ;
    rulesObj = TablePreFilter(rulesObj, TablePreFilterConfig.Ip, ipRuleContext)
    const [IPs, setIPs] = useState([]);
    const toast = useRef(null);
    const tableCrud = props.tableCrud === undefined ? []: props.tableCrud;
    const emptyIpRule = {
        'ip': null,
        'service': null,
        'blockStatus': null,
    }
    
    // Block, Unblock

    const [ipRuleDialog, setIPRuleDialog] = useState(false);
    const [viewIPRuleDialog, setViewIPRuleDialog] = useState(false);
    const [deleteIPRuleDialog, setDeleteIPRuleDialog] = useState(false);
    const [deleteIPsRuleDialog, setdeleteIPsRuleDialog] = useState(false);
    const [rejectIPRuleRequestDialog, setRejectIPRuleRequestDialog] = useState(false);
    const [rejectIPsRuleRequestDialog, setRejectIPsRuleRequestDialog] = useState(false);
    const [approveIPRuleRequestDialog, setApproveIPRuleRequestDialog] = useState(false);
    const [approveIPsRuleRequestDialog, setApproveIPsRuleRequestDialog] = useState(false);
    const [cancelIPRuleRequestDialog, setCancelIPRuleRequestDialog] = useState(false);
    const [cancelIPsRuleRequestDialog, setCancelIPsRuleRequestDialog] = useState(false);
    const [ipRule, setIPRule] = useState(emptyIpRule);
    const [selectedRules, setSelectedRules] = useState(null);
    const [submitted, setSubmitted] = useState(false);


    // const openNew = () => {
    //     setIPRule(emptyIpRule);
    //     setSubmitted(false);
    //     setIPRuleDialog(true);
    // };

    const hideDialog = () => {
        setSubmitted(false);
        setIPRuleDialog(false);
    };

    const hideViewIPRuleDialog = () => {
        setViewIPRuleDialog(false);
    };

    const hideDeleteIPRuleDialog = () => {
        setDeleteIPRuleDialog(false);
    };

    const hideDeleteIPsRuleDialog = () => {
        setdeleteIPsRuleDialog(false);
    };


    const hideRejectIPRuleDialog = () => {
        setRejectIPRuleRequestDialog(false);
    };

    const hideRejectIPsRuleDialog = () => {
        setRejectIPsRuleRequestDialog(false);
    };

    const hideApproveIPRuleDialog = () => {
        setApproveIPRuleRequestDialog(false);
    };

    const hideApproveIPsRuleDialog = () => {
        setApproveIPsRuleRequestDialog(false);
    };

    const hideCancelIPRuleDialog = () => {
        setCancelIPRuleRequestDialog(false);
    };

    const hideCancelIPsRuleDialog = () => {
        setCancelIPsRuleRequestDialog(false);
    };

    const saveIPRule = () => {
        setSubmitted(true);

        if (ipRule.name) {
            let ipRules = [...IPs];
            let _ipRule = { ...ipRule };

            if (_ipRule.id) {
                const index = findIndexById(ipRule.id);

                ipRules[index] = _ipRule;
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Rule Updated', life: 3000 });
            } else {
                ipRule.id = createId();
                // _product.image = 'product-placeholder.svg';
                ipRules.push(_ipRule);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Rule Created', life: 3000 });
            }

            setIPs(ipRules);
            setIPRuleDialog(false);
            setIPRule(editIPRule);
        }
    };

    
    const editIPRule = (ipRule) => {
        setIPRule({ ...ipRule });
        setIPRuleDialog(true);
    };

    const viewIPRule = (ipRule) => {
        setIPRule(ipRule);
        setViewIPRuleDialog(true);
    };

    const confirmDeleteIPRule = (ipRule) => {
        setIPRule(ipRule);
        setDeleteIPRuleDialog(true);
    };

    const confirmApproveIPRuleRequest = (ipRule) => {

        setIPRule(ipRule);
        setApproveIPRuleRequestDialog(true);
    };

    const confirmRejectIPRuleRequest = (ipRule) => {
        setIPRule(ipRule);
        setRejectIPRuleRequestDialog(true);
    };

    
    const confirmCancelIPRuleRequest = (ipRule) => {
        setIPRule(ipRule);
        setCancelIPRuleRequestDialog(true);
    };

    let ApproveRowAction = (rowData, disabled) => { return(<></>)};
    let RejectRowAction = (rowData, disabled) => { return(<></>)};
    let CancelRowAction = (rowData, disabled) => { return(<></>)};
    let EditRowAction = (rowData, disabled) => { return(<></>)};
    let DeleteRowAction = (rowData, disabled) => { return(<></>)};
    let ViewRowAction = ({rowData}) => { return (
        <Button  icon="pi pi-eye" rounded outlined className="mr-1" 
            onClick={() => viewIPRule({...rowData})} />)};
    if (tableCrud.includes("Approve")){
        ApproveRowAction = ({rowData, isDisabled}) => { return( 
            <Button disabled={isDisabled} icon="pi pi-check" rounded outlined className="mr-1" severity="success" 
                onClick={() => confirmApproveIPRuleRequest({...rowData})} />)};
    }

    if (tableCrud.includes("Reject")){
        RejectRowAction = ({rowData, isDisabled}) => { return (
            <Button disabled={isDisabled} icon="pi pi-times" rounded outlined className="mr-1" severity="danger" 
                onClick={() => confirmRejectIPRuleRequest({...rowData})} />)};
    }
    if (tableCrud.includes("Cancel")){
        CancelRowAction = ({rowData, isDisabled}) => { return (
            <Button disabled={isDisabled} icon="pi pi-trash" rounded outlined className="mr-1" severity="danger"
                onClick={() => confirmCancelIPRuleRequest({...rowData})} />)};
    }
    if (tableCrud.includes("Edit")){
        EditRowAction = ({rowData, isDisabled}) => { return (
            <Button disabled={isDisabled} icon="pi pi-pencil" rounded outlined className="mr-1"
                onClick={() => editIPRule({...rowData})} />)};
    }
    if (tableCrud.includes("Delete")){
        DeleteRowAction = ({rowData, isDisabled}) => { return (
            <Button disabled={isDisabled}  icon="pi pi-check-circle" rounded outlined className="mr-1" severity="success"
                onClick={() => confirmDeleteIPRule({...rowData})} />)};
    }

    const actionBodyTemplate = (rowData) => {
        const isRequestApproved = rowData.requestState !== undefined && rowData.requestState === "Approved";
        const isRequestSuccessful = rowData.requestState !== undefined && rowData.requestState === "Success";

        return (
            <React.Fragment>
                <ViewRowAction rowData={rowData}/>
                <ApproveRowAction rowData={rowData} isDisabled={isRequestApproved || isRequestSuccessful || !userContext.hasOpsPrivelege}/>
                <RejectRowAction rowData={rowData} isDisabled={isRequestApproved || isRequestSuccessful || !userContext.hasOpsPrivelege}/>
                <CancelRowAction rowData={rowData} isDisabled={isRequestApproved || isRequestSuccessful}/>
                <EditRowAction rowData={rowData} isDisabled={false}/>
                <DeleteRowAction rowData={rowData} isDisabled={rowData.blockStatus === "Allow"}/>
            </React.Fragment>
        );
    };


    const deleteIPRule = () => {

        requestRuleUnblock([ipRule], {...requestInfo, ...{'description': requestDescription}},
                {'ref': toast, 'success': "IP allow request submitted",
                "error": "IP allow request failed"})
        setDeleteIPRuleDialog(false);
        setIPRule(emptyIpRule);
        setRequestDescription("");
    };

    const approveIPRuleRequest = () => {
        
        approveRequest([ipRule], {...requestInfo, ...{'description': requestDescription}},
        {'ref': toast, 'success': "IP rule request approved",
                "error": "IP rule request approval failed"})

        setApproveIPRuleRequestDialog(false);
        //setIPRule(emptyIpRule);
        setRequestDescription("");
    };

    const rejectIPRuleRequest = () => {
        rejectRequest([ipRule], {...requestInfo, ...{'description': requestDescription}},
        {'ref': toast, 'success': "IP rule request rejected",
                "error": "IP rule request rejection failed"})
        setRejectIPRuleRequestDialog(false);
        //setIPRule(emptyIpRule);
        setRequestDescription("");
    };

    const cancelIPRuleRequest = () => {
        if (!checkUserDeleteRequest([ipRule], requestInfo)){
            toast.current.show({ severity: 'error', summary: 'Validation Failed', detail: 'Not authorized to Delete other users request.', life: 3000 });
            return;
        }
        deleteRequest([ipRule], {...requestInfo, ...{'description': requestDescription}},
        {'ref': toast, 'success': "IP rule request deleted",
                "error": "IP rule request deletion failed"})
        setCancelIPRuleRequestDialog(false);
        setIPRule(emptyIpRule);
    };

    // const deleteIPRuleRequest = () => {
    //     deleteRequest([ipRule])
    //     setDeleteIPRuleDialog(false);
    //     setIPRule(emptyIpRule);
    //     toast.current.show({ severity: 'success', summary: 'Successful', detail: 'IP Rule Request Deleted', life: 3000 });
    // };

    const findIndexById = (id) => {
        let index = -1;

        for (let i = 0; i < ipRule.length; i++) {
            if (ipRule[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        return id;
    };

    const confirmDeleteSelected = () => {
        setdeleteIPsRuleDialog(true);

    };
    const confirmApproveSelected = () => {
        setApproveIPsRuleRequestDialog(true);

    };
    const confirmRejectSelected = () => {
        setRejectIPsRuleRequestDialog(true);
    ;
    };
    const confirmCancelSelected = () => {
        setCancelIPsRuleRequestDialog(true);
    
    };
    const deleteSelectedIPs = () => {

        requestRuleUnblock(selectedRules, {...requestInfo, ...{'description': requestDescription}},
        {'ref': toast, 'success': "IP(s) allow request submitted",
                "error": "IP(s) allow request failed"});
        setdeleteIPsRuleDialog(false);
        setSelectedRules(null);
        // setDeleteIPRuleDialog(false);
        setRequestDescription("");
    };

    const approveSelectedRulesRequest = () => {
        approveRequest(selectedRules, {...requestInfo, ...{'description': requestDescription}},
        {'ref': toast, 'success': "IP(s) rule request approved",
                "error": "IP(s) rule request approval failed"});
        setApproveIPsRuleRequestDialog(false);
        setSelectedRules(null);
        setRequestDescription("");
    };

    const rejectSelectedRulesRequest = () => {
        rejectRequest(selectedRules, {...requestInfo, ...{'description': requestDescription}},
        {'ref': toast, 'success': "IP(s) rule request rejected",
                "error": "IP(s) rule request rejection failed"});
        setRejectIPsRuleRequestDialog(false);
        setSelectedRules(null);
        setRequestDescription("");
    };

    const cancelSelectedRulesRequest = () => {
        if (!checkUserDeleteRequest(selectedRules, requestInfo)){
            toast.current.show({ severity: 'error', summary: 'Validation Failed', 
                detail: 'Not authorized to Delete other users request.', life: 3000 });
            return;
        }
        deleteRequest(selectedRules, {...requestInfo, ...{'description': requestDescription}},
        {'ref': toast, 'success': "IP(s) rule request deleted",
                "error": "IP(s) rule request deletion failed"});
        setCancelIPsRuleRequestDialog(false);
        setSelectedRules(null);
    };

    const ipTableCRUD = () => {
        return (
            <div className="inline-block align-bottom ml-0.5">
                {
                    tableCrud.includes("Approve") &&
                    <Button label="Approve" icon="pi pi-check" severity="success"
                        onClick={confirmApproveSelected} disabled={!selectedRules || !selectedRules.length || !userContext.hasOpsPrivelege} className="mx-1"/>
                }
                {
                    tableCrud.includes("Reject") &&
                    <Button label="Reject" icon="pi pi-times" severity="danger"
                        onClick={confirmRejectSelected} disabled={!selectedRules || !selectedRules.length || !userContext.hasOpsPrivelege} className="mx-1"/>
                }
           
                {
                    tableCrud.includes("Cancel") &&
                    <Button label="Delete" icon="pi pi-trash" severity="danger"
                        onClick={confirmCancelSelected} disabled={!selectedRules || !selectedRules.length} className="mx-1"/>
                }
                {/* <Button label="New" icon="pi pi-plus" severity="success" onClick={openNew} className="mx-1 " />
                <Button label="Edit" icon="pi pi-plus" severity="warning" onClick={openNew} className="mx-0.5"/> */}
                {
                    tableCrud.includes("Delete") &&
                    <Button label="Allow" icon="pi pi-check-circle" severity="success"
                        onClick={confirmDeleteSelected} disabled={!selectedRules || !selectedRules.length} className="mx-1"/>
                }

            </div>
        );
    };

    const onIPChange = (e, ip) => {
        const val = (e.target && e.target.value) || '';
        let _ipRule = { ...ipRule };

        _ipRule[`${ip}`] = val;

        setIPRule(+ipRule);
    };

    // Tracking filtered rows
    const [filteredIPs, setFilteredIPs] = useState([]);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilteredIPs(_filters);
        setGlobalFilterValue(value);
    };

    const serviceFilterTemplate = (options) => {
        return filterTemplate(options, "Service Picker", ipRuleContext.generalStatus.SupportedServices);
    };

    const ruleSourceFilterTemplate = (options) => {
        return filterTemplate(options, "Source Picker", ipRuleContext.generalStatus.SupportedSources.slice(1));
    };
    

    // IP Table column definitions

    const ipColumns = useMemo (() => {
        let tempIPColumns = {...ipRuleTableSchema}
        tempIPColumns['service']['filterElement'] = serviceFilterTemplate;
        tempIPColumns['blockStatus']['filterElement'] = blockStatusFilterTemplate
        if (props.tablePage === "ViewPage"){
            tempIPColumns['blockStatus']['body'] = blockStatusBodyTemplate;
        }
        tempIPColumns['requestState']['body'] = requestStatusBodyTemplate; 
        tempIPColumns['region']['filterElement'] = blockRegionFilterTemplate;
        tempIPColumns['ruleSource']['filterElement'] = ruleSourceFilterTemplate;   
        return tempIPColumns;
        // eslint-disable-next-line
    }, []);

    if (props.tablePage === "ViewPage"){
        delete ipColumns.requestState;
        delete ipColumns.requestTime;
        delete ipColumns.requestedByUser;
        delete ipColumns.requestedByUserName;
        delete ipColumns.lastUpdateTime;
        delete ipColumns.lastUpdatedByUser;
        delete ipColumns.lastUpdatedByUserName;
        delete ipColumns.requestDescription;
        delete ipColumns.approveDescription;
    }
    else {
        delete ipColumns.path;
    }
    if(props.page === "Weather" || props.page === "XiO"){
        delete ipColumns.path
    }
    
    // Tracking column visibility
    const [visibleColumns, setVisibleColumns] = useState(() => {
        let defaultVisibleColumns = [];
        for (let columnField in ipColumns){
            if (!('defaultVisible' in ipColumns[columnField]) || (ipColumns[columnField]['defaultVisible'])){
                defaultVisibleColumns.push({'field': columnField, 'header': ipColumns[columnField]['header']});
            }
        }
        return defaultVisibleColumns;
    });

    // Populating Column picker options - ALL columns
    const columnPickerOptions = useMemo(() => {
        let tempColumnPickerOptions = [];
        for (let columnField in ipColumns){
            tempColumnPickerOptions.push({
                'header': ipColumns[columnField]['header'],'field': columnField
            });
            
        }
        return tempColumnPickerOptions;
        // eslint-disable-next-line
    }, []);


    // GT: dataTable use Reference
    const dt = useRef(null);


    const [filters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        ip: { value: null, matchMode: FilterMatchMode.IN },
        blockStatus: { value: null, matchMode: FilterMatchMode.IN },
        region: { value: null, matchMode: FilterMatchMode.IN },
        service: { value: null, matchMode: FilterMatchMode.IN },
        ruleSource: { value: null, matchMode: FilterMatchMode.IN },
        ruleName: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        ruleType: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        path: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        requestState: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        requestTime: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        requestedByUser: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        requestedByUserName: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        lastUpdateTime: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        lastUpdatedByUser: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        lastUpdatedByUserName: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        requestDescription: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        approveDescription: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]}
    });
    const [loading, setLoading] = useState(true);
    // Loading the Table rows
    useEffect(() => {
        setIPs(rulesObj);
        setFilteredIPs(rulesObj);
        setLoading(false);
    }, [ipRuleContext]); // eslint-disable-line react-hooks/exhaustive-deps

    const header = renderTableHeader(dt, filteredIPs, visibleColumns,  columnPickerOptions,
         setVisibleColumns, globalFilterValue, onGlobalFilterChange, ipTableCRUD);
    // Rendering Table columns
    const tableColumn = renderTableColumn(ipColumns, visibleColumns, actionBodyTemplate)

    const ipRuleDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" onClick={saveIPRule} />
        </React.Fragment>
    );

    const ipRuleViewDialogFooter = (
        <React.Fragment>
            <Button label="Close" icon="pi pi-times" outlined onClick={hideViewIPRuleDialog} />
        </React.Fragment>
    );
    const deleteIPRuleFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteIPRuleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteIPRule} />
        </React.Fragment>
    );
    const deleteIPsRuleDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteIPsRuleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteSelectedIPs} />
        </React.Fragment>
    );
    const rejectIPRuleFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideRejectIPRuleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={rejectIPRuleRequest} />
        </React.Fragment>
    );
    const rejectIPsRuleDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideRejectIPsRuleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={rejectSelectedRulesRequest} />
        </React.Fragment>
    );

    const approveIPRuleFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideApproveIPRuleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={approveIPRuleRequest} />
        </React.Fragment>
    );
    const approveIPsRuleDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideApproveIPsRuleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={approveSelectedRulesRequest} />
        </React.Fragment>
    );

    const cancelIPRuleFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideCancelIPRuleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={cancelIPRuleRequest} />
        </React.Fragment>
    );
    const cancelIPsRuleDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideCancelIPsRuleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={cancelSelectedRulesRequest} />
        </React.Fragment>
    );

    return (
        <>
            <Toast ref={toast} />
            {/* <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar> */}
            {Object.keys(rulesObj).length > 0 && 
                <DataTable  
                    scrollable scrollHeight="800px" 
                    isDataSelectable={isRowSelectable}
                    selection={selectedRules} onSelectionChange={(e) => setSelectedRules(e.value)}
                    tableStyle={{minWidth: "1200px"}} style={{'fontSize': '0.8em'}}
                    stripedRows
                    showGridlines 
                    value={IPs} 
                    ref={dt} 
                    paginator reorderableColumns
                    header={header} 
                    rows={10}
                    size="small"
                    loading={loading}
                    sortMode="multiple"
                    exportFilename='IPBlockStatusReport'
                    // multiSortMeta={multiSortMeta}
                    onValueChange={filteredData => {setFilteredIPs(filteredData);}}
                    resizableColumns columnResizeMode="expand" 
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    rowsPerPageOptions={[10, 25, 50, 100, 1000]}
                    dataKey="ruleId" selectionMode="checkbox" 
                    filters={filters} filterDisplay="menu" 
                    emptyMessage="No IPs found." 
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"> 
                    {tableColumn}
                </DataTable>
            }

            <Dialog visible={ipRuleDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="IP Rules" modal  footer={ipRuleDialogFooter} className="p-fluid" onHide={hideDialog}>
                <div className="field">
                    <label htmlFor="IP" className="font-bold">
                        IP
                    </label>
                    <InputText id="ip" value={ipRule.ip} onChange={(e) => onIPChange(e, 'ip')} required autoFocus className={classNames({ 'p-invalid': submitted && !ipRule.ip })} />
                    {submitted && !ipRule.ip && <small className="p-error">Name is required.</small>}
                </div>ipRUle
            </Dialog>
            <Dialog visible={viewIPRuleDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Device Rule"
                modal footer={ipRuleViewDialogFooter} onHide={hideViewIPRuleDialog}>
                    <div className="confirmation-content">
                        <RowTable rowData={ipRule} tableSchema={ipRuleTableSchema}/>
                    </div>
            </Dialog>
            {
               tableCrud.includes("Delete") && 
               <>
               <Dialog visible={deleteIPRuleDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm"
            modal footer={deleteIPRuleFooter} onHide={hideDeleteIPRuleDialog}>
               <div className="confirmation-content">
                   <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                   {ipRule && (
                    <>
                        <span>
                           Are you sure you want to Allow <b>{"IP=" + ipRule.ip}</b> ?
                       </span>
                       <RenderRequestDescriptionDiv description={requestDescription} setRequestDescription={setRequestDescription}/>
                    </>
                       
                   )}
               </div>
           </Dialog>
            <Dialog visible={deleteIPsRuleDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm"
            modal footer={deleteIPsRuleDialogFooter} onHide={hideDeleteIPsRuleDialog}>
               <div className="confirmation-content">
                   <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                   {ipRule && <span>Are you sure you want to Allow the Selected IP(s)?</span>}
                   <RenderRequestDescriptionDiv description={requestDescription} setRequestDescription={setRequestDescription}/>
               </div>
           </Dialog>
               </>

            }

            {
               tableCrud.includes("Cancel") && 
               <>

            <Dialog visible={cancelIPRuleRequestDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm"
             modal footer={cancelIPRuleFooter} onHide={hideCancelIPRuleDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {ipRule && (
                        <span>
                            Are you sure you want to Delete <b>{ipRule.ip}</b> Rule Request?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={cancelIPsRuleRequestDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm"
             modal footer={cancelIPsRuleDialogFooter} onHide={hideCancelIPsRuleDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {ipRule && <span>Are you sure you want to Delete the Selected Rule Request(s)?</span>}
                </div>
            </Dialog>
            </>
}

{
               tableCrud.includes("Reject") && 
               <>

            <Dialog visible={rejectIPRuleRequestDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm"
             modal footer={rejectIPRuleFooter} onHide={hideRejectIPRuleDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {ipRule && (
                        <>
                        <span>
                            Are you sure you want to Reject <b>{ipRule.ip}</b> Rule Request?
                        </span>
                        <RenderRequestDescriptionDiv description={requestDescription} setRequestDescription={setRequestDescription}/>
                        </>

                    )}
                </div>
            </Dialog>

            <Dialog visible={rejectIPsRuleRequestDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm"
             modal footer={rejectIPsRuleDialogFooter} onHide={hideRejectIPsRuleDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {ipRule && <span>Are you sure you want to Reject the Selected Rule Request(s)?</span>}
                    <RenderRequestDescriptionDiv description={requestDescription} setRequestDescription={setRequestDescription}/>
                </div>
            </Dialog>
            </>
}
{
               tableCrud.includes("Approve") && 
               <>

            <Dialog visible={approveIPRuleRequestDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm"
             modal footer={approveIPRuleFooter} onHide={hideApproveIPRuleDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {ipRule && (
                        <>
                            <span>
                                Are you sure you want to Approve <b>{ipRule.ip}</b> Rule Request?
                            </span>
                        <RenderRequestDescriptionDiv description={requestDescription} setRequestDescription={setRequestDescription}/>
                        </>

                    )}
                </div>
            </Dialog>

            <Dialog visible={approveIPsRuleRequestDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm"
             modal footer={approveIPsRuleDialogFooter} onHide={hideApproveIPsRuleDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {ipRule && <span>Are you sure you want to Approve the Selected Rule Request(s)?</span>}
                    <RenderRequestDescriptionDiv description={requestDescription} setRequestDescription={setRequestDescription}/>
                </div>
            </Dialog>
            </>
}
        </>
        
    );
}