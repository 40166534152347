import { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { RouteGuard } from "./auth/RouteGuard";
import { CustomNavigationClient } from "./auth/ClientNavigation";
import Typography from "@mui/material/Typography";
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";


import { PageLayout } from "./ui-components/PageLayout";
import { Logout } from "./pages/Logout";

import { IotHubBlockedDeviceReport } from "./pages/xio/XiOLayout";
import { WeatherBlockedIPReport } from "./pages/weather/WeatherLayout";
import { FirmwareDownloadBlockStatusReport } from "./pages/firmware/FirmwareLayout";
import { RequestLayout } from "./pages/request/RequestLayout";


import {groups} from './auth/AuthConfig'
import { Dashboard } from "./pages/dashboardPages/DashBoardLayout";

function App({ pca }) {


  return (
    <ClientSideNavigation pca={pca}>
      <MsalProvider instance={pca}>
        <PageLayout>
          {/* <Grid container justifyContent="center"> */}
            <PageContent />
          {/* </Grid> */}
        </PageLayout>
      </MsalProvider>
    </ClientSideNavigation>
  );
}

/**
 *  This component is optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
 */ 
function ClientSideNavigation({ pca, children }) {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  // react-router-dom v6 doesn't allow navigation on the first render - delay rendering of MsalProvider to get around this limitation
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    setFirstRender(false);
  }, []);

  if (firstRender) {
    return null;
  }

  return children;
}

function PageContent() {
  return (
    <>
    <AuthenticatedTemplate>
      {/* Display Pages only if Authenticated */}
      <Pages />
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
    <Typography variant="h6">
          <center>Sign in to view Blocked Devices, IP & Country Report</center>
          </Typography>
      </UnauthenticatedTemplate>

  </>
  )
}

function Pages() {
  return (
    <Routes>
      <Route path="/"  element={
        <RouteGuard requiredGroups={[groups.EsOps, groups.CrestronSupportGroup]}>
            <Dashboard />
        </RouteGuard> } />
        <Route path="/home"  element={
        <RouteGuard requiredGroups={[groups.EsOps, groups.CrestronSupportGroup]}>
            <Dashboard />
        </RouteGuard> } />
        <Route path="/XiOBlockStatusReport"  element={
        <RouteGuard requiredGroups={[groups.EsOps, groups.CrestronSupportGroup]}>
            <IotHubBlockedDeviceReport />
        </RouteGuard> } />
        <Route path="/WeatherBlockStatusReport"  element={
        <RouteGuard requiredGroups={[groups.EsOps, groups.CrestronSupportGroup]}>
            <WeatherBlockedIPReport />
        </RouteGuard> } />
        <Route path="/FirmwareDownloadBlockStatusReport"  element={
        <RouteGuard requiredGroups={[groups.EsOps, groups.CrestronSupportGroup]}>
            <FirmwareDownloadBlockStatusReport />
        </RouteGuard> } />
        <Route path="/Request"  element={
        <RouteGuard requiredGroups={[groups.EsOps, groups.CrestronSupportGroup]}>
            <RequestLayout />
        </RouteGuard> } />
      <Route path="/logout" element={<Logout />} />
    </Routes>
  )
}

export default App;
