import React, { useContext } from "react";

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import { Accordion, AccordionTab } from 'primereact/accordion';

import { RulesContext } from "../../ui-components/ContextManager";

import { CountryRuleTable } from "../tables/CountryRulesTable";
import { IoTRuleTable } from "../tables/IotRulesTable";
import { IPRuleTable } from "../tables/IpRulesTable";

import { RenderSubKPI, TableKpiFilterConfig, AccordionHeaderDiv } from "../../utils/layout_util";
import { BlockStatusReport } from "../BlockStatus";



 // Blocked Countries KPI

function BlockedCountriesKPI({rulesContext}){
    return (
        <div className="flex flex-wrap justify-center gap-x-2 gap-y-1 text-1xl">
        <RenderSubKPI context={rulesContext}
            kpiText="East Blocked Countries" 
            kpiValue={rulesContext.xio.eastWAFDenyCountriesCount}
            kpiIconColour="red" kpiIcon="pi-shield"
            category={TableKpiFilterConfig.XiOEastCountry.category}
            filterValue={TableKpiFilterConfig.XiOEastCountry.filterValue}/>
        <RenderSubKPI context={rulesContext} kpiText="West Blocked Countries" 
            kpiValue={rulesContext.xio.westWAFDenyCountriesCount} 
            kpiIconColour="red" kpiIcon="pi-shield"
            category={TableKpiFilterConfig.XiOWestCountry.category}
            filterValue={TableKpiFilterConfig.XiOWestCountry.filterValue}/>
    </div>

    )
}

 // Disabled Devices KPI

function DisabledDevicesKPI({rulesContext}){
    return (
        <div className="flex flex-wrap justify-center gap-x-2 gap-y-1 text-1xl">
        <RenderSubKPI context={rulesContext} kpiText="East Disabled Devices" 
            kpiValue={rulesContext.xio.eastIoTDenyDevicesCount} 
            kpiIconColour="red" kpiIcon="pi-shield"
            category={TableKpiFilterConfig.XiOEastDevice.category}
            filterValue={TableKpiFilterConfig.XiOEastDevice.filterValue}/>
        <RenderSubKPI context={rulesContext} kpiText="West Disabled Devices" 
            kpiValue={rulesContext.xio.westIoTDenyDevicesCount} 
            kpiIconColour="red" kpiIcon="pi-shield"
            category={TableKpiFilterConfig.XiOWestDevice.category}
            filterValue={TableKpiFilterConfig.XiOWestDevice.filterValue}/>
    </div>

    )
}

// Allow IP KPI

function AllowIPsKPI({rulesContext}){
    return (
        <div className="flex flex-wrap justify-center gap-x-2 gap-y-1 text-1xl">
       <RenderSubKPI context={rulesContext} kpiText="East Allow IPs" 
            kpiValue={rulesContext.xio.eastWAFAllowIPsCount} 
            kpiIconColour="green" kpiIcon="pi-shield"
            category={TableKpiFilterConfig.XiOEastIp.category}
            filterValue={TableKpiFilterConfig.XiOEastIp.filterValue}/>
        <RenderSubKPI context={rulesContext} kpiText="West Allow IPs" 
            kpiValue={rulesContext.xio.westWAFAllowIPsCount} 
            kpiIconColour="green" kpiIcon="pi-shield"
            category={TableKpiFilterConfig.XiOWestIp.category}
            filterValue={TableKpiFilterConfig.XiOWestIp.filterValue}/>
    </div>

    )
}

// Rendering KPI layout
function KPILayout(){
    const rulesContext = useContext(RulesContext);
    return (

        <div className="flex flex-wrap justify-center gap-x-1 gap-y-1  text-1xl ">
           <div className="bubs-dynamic">
                    <div className="bubs-group-kpi">
                        <span className="block text-kpi-group-header  mb-1 text-center">Disabled Devices</span>
                        <DisabledDevicesKPI rulesContext={rulesContext}/>
                    </div>
            </div> 

            <div className="bubs-dynamic">
                    <div className="bubs-group-kpi">
                        <span className="block text-kpi-group-header  mb-1 text-center">Blocked Countries</span>
                        <BlockedCountriesKPI rulesContext={rulesContext}/>
                    </div>
            </div> 
            <div className="bubs-dynamic">
                    <div className="bubs-group-kpi">
                        <span className="block text-kpi-group-header  mb-1 text-center">Allow IPs</span>
                        <AllowIPsKPI rulesContext={rulesContext}/>

                    </div>
            </div> 

        </div>

    )
}

// Rendering Dashboard Components
function RenderIotHubBlockedDeviceReport() {
    const rulesContext = useContext(RulesContext)
    return (
      <>
        <div>
            <KPILayout/>
            <Accordion multiple className="w-full overflow-auto mt-3"> 
                {rulesContext.displayDeviceTable && 
                    <AccordionTab header={AccordionHeaderDiv("Status by Device", rulesContext.generalStatus.IoTRegistryReadInterval)}>
                    <IoTRuleTable iotRuleContext={rulesContext} tablePage={"ViewPage"} tableCrud={['Delete']}/>
                </AccordionTab>}
                {rulesContext.displayIpTable && 
                <AccordionTab header={AccordionHeaderDiv("Status by IP", rulesContext.generalStatus.WAFReadInterval)}>
                    <IPRuleTable rulesObj={rulesContext.xio.WAFIPs} ipRuleContext={rulesContext}
                    tablePage={"ViewPage"} tableCrud={['Delete']} page={"XiO"}/>
                </AccordionTab>}
                {rulesContext.displayCountryTable &&
                    <AccordionTab header={AccordionHeaderDiv("Status by Country", rulesContext.generalStatus.WAFReadInterval)}>
                    <CountryRuleTable  countryRuleContext={rulesContext} 
                    rulesObj={rulesContext.xio.WAFCountries} tablePage={"ViewPage"} tableCrud={['Delete']} />
                </AccordionTab>}
          </Accordion>
        </div>
      </>
    )
  }
  
// Embedding rendered dashbard to Main layout
export function IotHubBlockedDeviceReport() {
    return BlockStatusReport(RenderIotHubBlockedDeviceReport);
}
