import React, { useState, useMemo, useRef, useEffect, useContext  } from "react";
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from "primereact/datatable";

import { Button } from 'primereact/button';

import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { filterTemplate, renderTableColumn, renderTableHeader, 
    blockRegionFilterTemplate, blockStatusFilterTemplate,
     blockStatusBodyTemplate, TablePreFilter, requestRuleUnblock,
    approveRequest, deleteRequest,rejectRequest, isRowSelectable,
     checkUserDeleteRequest, RenderRequestDescriptionDiv, requestStatusBodyTemplate } from "../../lib/Table";
     import RowTable from "../../lib/RowTable";
import { TablePreFilterConfig } from "../../utils/layout_util";
import { UserContext } from "../../ui-components/ContextManager";
import { deviceRuleTableSchema } from "./IotRulesTableSchema";

// IoT Blocked devices table
export function IoTRuleTable(props) {
    const userContext = useContext(UserContext);
    const [requestDescription, setRequestDescription] = useState(""); 
    const requestInfo = {'username': userContext.username, 'name' : userContext.name};
    const iotRuleContext = props.iotRuleContext;
    let rulesObj = props.rulesObj !== undefined ? 
        iotRuleContext[props.region]:
        iotRuleContext.deviceRules;
    rulesObj = TablePreFilter(rulesObj, TablePreFilterConfig.Device, iotRuleContext) ;
    const [devices, setDevices] = useState([]);
    
    const toast = useRef(null);
    const tableCrud = props.tableCrud === undefined ? []: props.tableCrud;

    const emptyDeviceRule = {
        'deviceId': null,
        'service': null,
        'blockStatus': null,
    }

    // Block, Unblock

    const [deviceRuleDialog, setDeviceRuleDialog] = useState(false);
    const [viewDeviceRuleDialog, setViewDeviceRuleDialog] = useState(false);
    const [deleteDeviceRuleDialog, setDeleteDeviceRuleDialog] = useState(false);
    const [deleteDevicesRuleDialog, setdeleteDevicesRuleDialog] = useState(false);
    const [rejectDeviceRuleRequestDialog, setRejectDeviceRuleRequestDialog] = useState(false);
    const [rejectDevicesRuleDialog, setRejectDevicesRuleRequestDialog] = useState(false);
    const [approveDeviceRuleRequestDialog, setApproveDeviceRuleRequestDialog] = useState(false);
    const [approveDevicesRuleRequestDialog, setApproveDevicesRuleRequestDialog] = useState(false);
    const [cancelDeviceRuleRequestDialog, setCancelDeviceRuleRequestDialog] = useState(false);
    const [cancelDevicesRuleRequestDialog, setCancelDevicesRuleRequestDialog] = useState(false);
    const [deviceRule, setDeviceRule] = useState(emptyDeviceRule);
    const [selectedRules, setSelectedRules] = useState(null);
    const [submitted, setSubmitted] = useState(false);


    // const openNew = () => {
    //     setDeviceRule(emptyDeviceRule);
    //     setSubmitted(false);
    //     setDeviceRuleDialog(true);
    // };


    const hideDialog = () => {
        setSubmitted(false);
        setDeviceRuleDialog(false);
    };

    const hideViewDeviceRuleDialog = () => {
        setViewDeviceRuleDialog(false);
    };

    const hideDeleteDeviceRuleDialog = () => {
        setDeleteDeviceRuleDialog(false);
    };


    const hideDeleteDevicesRuleDialog = () => {
        setdeleteDevicesRuleDialog(false);
    };


    const hideRejectDeviceRuleDialog = () => {
        setRejectDeviceRuleRequestDialog(false);
    };

    const hideRejectDevicesRuleDialog = () => {
        setRejectDevicesRuleRequestDialog(false);
    };

    const hideApproveDeviceRuleDialog = () => {
        setApproveDeviceRuleRequestDialog(false);
    };

    const hideApproveDevicesRuleDialog = () => {
        setApproveDevicesRuleRequestDialog(false);
    };

    const hideCancelDeviceRuleDialog = () => {
        setCancelDeviceRuleRequestDialog(false);
    };

    const hideCancelDevicesRuleDialog = () => {
        setCancelDevicesRuleRequestDialog(false);
    }
    
    const saveDeviceRule = () => {
        setSubmitted(true);

        if (deviceRule.name) {
            let deviceRules = [...devices];
            let _deviceRule = { ...deviceRule };

            if (_deviceRule.id) {
                const index = findIndexById(deviceRule.id);

                deviceRules[index] = _deviceRule;
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Rule Updated', life: 3000 });
            } else {
                deviceRule.id = createId();
                // _product.image = 'product-placeholder.svg';
                deviceRules.push(_deviceRule);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Rule Created', life: 3000 });
            }

            setDevices(deviceRules);
            setDeviceRuleDialog(false);
            setDeviceRule(editDeviceRule);
        }
    };

    
    const editDeviceRule = (deviceRule) => {
        setDeviceRule(deviceRule);
        setDeviceRuleDialog(true);
    };
    const viewDeviceRule = (deviceRule) => {
        setDeviceRule(deviceRule);
        setViewDeviceRuleDialog(true);
    };

    const confirmDeleteDeviceRule = (deviceRule) => {
        setDeviceRule(deviceRule);
        setDeleteDeviceRuleDialog(true);
    };

    
    const confirmApproveDeviceRuleRequest = (deviceRule) => {
        setDeviceRule(deviceRule);
        setApproveDeviceRuleRequestDialog(true);
    };
    const confirmRejectDeviceRuleRequest = (deviceRule) => {
        setDeviceRule(deviceRule);
        setRejectDeviceRuleRequestDialog(true);
    };
    
    const confirmCancelDeviceRuleRequest = (deviceRule) => {
        setDeviceRule(deviceRule);
        setCancelDeviceRuleRequestDialog(true);
    }

    let ApproveRowAction = (rowData, disabled) => { return(<></>)};
    let RejectRowAction = (rowData, disabled) => { return(<></>)};
    let CancelRowAction = (rowData, disabled) => { return(<></>)};
    // let EditRowAction = (rowData, disabled) => { return(<></>)};
    let DeleteRowAction = (rowData) => { return(<></>)};
    let ViewRowAction = ({rowData}) => { return (
        <Button  icon="pi pi-eye" rounded outlined className="mr-1" 
            onClick={() => viewDeviceRule({...rowData})} />)};
    if (tableCrud.includes("Approve")){
        ApproveRowAction = ({rowData, disabled}) => { return( 
            
        <Button disabled={disabled} icon="pi pi-check" rounded outlined className="mr-1" severity="success"
            onClick={() => confirmApproveDeviceRuleRequest({...rowData})} />)};
    }

    if (tableCrud.includes("Reject")){
        RejectRowAction = ({rowData, disabled}) => { return (
            <Button disabled={disabled} icon="pi pi-times" rounded outlined className="mr-1" severity="danger" 
                onClick={() => confirmRejectDeviceRuleRequest({...rowData})} />)};
    }
    if (tableCrud.includes("Cancel")){
        CancelRowAction = ({rowData, disabled}) => { return (
            <Button disabled={disabled} icon="pi pi-trash" rounded outlined className="mr-1" severity="danger"
                onClick={() => confirmCancelDeviceRuleRequest({...rowData})} />)};
    }
    // if (tableCrud.includes("Edit")){
    //     EditRowAction = ({rowData, disabled}) => { return (
    //         <Button disabled={disabled} icon="pi pi-pencil" rounded outlined className="mr-1"
    //             onClick={() => editDeviceRule({...rowData})} />)};
    // }
    if (tableCrud.includes("Delete")){
        DeleteRowAction = ({rowData}) => {return (
            <Button  icon="pi pi-check-circle" rounded outlined className="mr-1" severity="success"
                onClick={() => confirmDeleteDeviceRule({...rowData})} />)};
    }
    const actionBodyTemplate = (rowData) => {
        const isRequestApproved = rowData.requestState !== undefined && rowData.requestState === "Approved";
        const isRequestSuccessful = rowData.requestState !== undefined && rowData.requestState === "Success";
        return (
            <React.Fragment>
                <ViewRowAction rowData={rowData}/>
                <ApproveRowAction rowData={rowData} disabled={isRequestApproved || isRequestSuccessful|| !userContext.hasOpsPrivelege}/>
                <RejectRowAction rowData={rowData} disabled={isRequestApproved || isRequestSuccessful || !userContext.hasOpsPrivelege}/>
                <CancelRowAction rowData={rowData} disabled={isRequestApproved || isRequestSuccessful}/>
                {/* <EditRowAction rowData={rowData} disabled={false}/> */}
                <DeleteRowAction rowData={rowData}/>
            </React.Fragment>
        );
    };


    const deleteDevice = () => {

        requestRuleUnblock([{ ...deviceRule }], {...requestInfo, ...{'description': requestDescription}},
            {'ref': toast, 'success': "Device enable request submitted",
                "error": "Device enable request failed"})
        // setDevices(deviceRules);
        setDeleteDeviceRuleDialog(false);
        setDeviceRule(emptyDeviceRule);
        setRequestDescription("");
    };


    const approveDeviceRuleRequest = () => {
        
        approveRequest([{ ...deviceRule }], {...requestInfo, ...{'description': requestDescription}},
        {'ref': toast, 'success': "Device rule request approved",
             "error": "Device Rule request approval failed"})

        setApproveDeviceRuleRequestDialog(false);
        setRequestDescription("");
        //setDeviceRule(emptyDeviceRule);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Device Rule Request Approved', life: 3000 });
    };

    const rejectDeviceRuleRequest = () => {
        rejectRequest([{ ...deviceRule }], {...requestInfo, ...{'description': requestDescription}},
        {'ref': toast, 'success': "Device(s) rule request rejected",
             "error": "Device(s) rule request rejection failed"})
        setRejectDeviceRuleRequestDialog(false);
        setRequestDescription("");
        //setDeviceRule(emptyDeviceRule);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Device Rule Request Rejected', life: 3000 });
    };

    const cancelDeviceRuleRequest = () => {
        if (!checkUserDeleteRequest([{ ...deviceRule }], requestInfo)){
            toast.current.show({ severity: 'error', summary: 'Validation Failed', detail: 'Not authorized to Delete other users request.', life: 3000 });
            return;
        }
        deleteRequest([{ ...deviceRule }], {...requestInfo, ...{'description': requestDescription}})
        setCancelDeviceRuleRequestDialog(false);
        // setDeviceRule(emptyIpRule);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Device Rule Request Deleted', life: 3000 });
    };

    const findIndexById = (id) => {
        let index = -1;

        for (let i = 0; i < deviceRule.length; i++) {
            if (deviceRule[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        return id;
    };

    const confirmDeleteSelected = () => {
        setdeleteDevicesRuleDialog(true);

    };

    const confirmApproveSelected = () => {
        setApproveDevicesRuleRequestDialog(true);

    };
    const confirmRejectSelected = () => {
        setRejectDevicesRuleRequestDialog(true);
  
    };

    const confirmCancelSelected = () => {
        setCancelDevicesRuleRequestDialog(true);

    };
    const deleteSelectedDevices = () => {

        requestRuleUnblock(selectedRules, {...requestInfo, ...{'description': requestDescription}}, 
            {'ref': toast, 'success': "Device(s) enable request submitted",
             "error": "Device(s) enable request failed"});
        setdeleteDevicesRuleDialog(false);
        setSelectedRules(null);
        setRequestDescription("");
    };

    const approveSelectedRulesRequest = () => {
        approveRequest(selectedRules, {...requestInfo, ...{'description': requestDescription}},
            {'ref': toast, 'success': "Device(s) rule request approved",
             "error": "Device(s) Rule request approval failed"});
        setApproveDevicesRuleRequestDialog(false);
        setSelectedRules(null);
        setRequestDescription("");
    };

    const rejectSelectedRulesRequest = () => {
        rejectRequest(selectedRules, {...requestInfo, ...{'description': requestDescription}},
            {'ref': toast, 'success': "Device(s) rule request rejected",
             "error": "Device(s) rule request rejection failed"});
        setRejectDevicesRuleRequestDialog(false);
        setSelectedRules(null);
        setRequestDescription("");
    };

    const cancelSelectedRulesRequest = () => {
        if (!checkUserDeleteRequest(selectedRules, requestInfo)){
            toast.current.show({ severity: 'error', summary: 'Validation Failed', 
                                detail: 'Not authorized to Delete other users request.', life: 3000 });
            return;
        }
        deleteRequest(selectedRules, {...requestInfo, ...{'description': requestDescription}},
            {'ref': toast, 'success': "Device(s) rule request deleted",
                "error": "Device(s) rule request deletion failed"});
        setCancelDevicesRuleRequestDialog(false);
        setSelectedRules(null);
    };

    const deviceTableCRUD = () => {
        return (
            <div className="inline-block align-bottom ml-0.5">
            
                {
                    tableCrud.includes("Approve") &&
                    <Button label="Approve" icon="pi pi-check" severity="success"
                        onClick={confirmApproveSelected} disabled={!selectedRules || !selectedRules.length || !userContext.hasOpsPrivelege} className="mx-1"/>
                }
                {
                    tableCrud.includes("Reject") &&
                    <Button label="Reject" icon="pi pi-times" severity="danger"
                        onClick={confirmRejectSelected} disabled={!selectedRules || !selectedRules.length || !userContext.hasOpsPrivelege} className="mx-1"/>
                }
                {
                    tableCrud.includes("Cancel") &&
                    <Button label="Delete" icon="pi pi-trash" severity="danger"
                        onClick={confirmCancelSelected} disabled={!selectedRules || !selectedRules.length} className="mx-1"/>
                }

                {/* <Button label="New" icon="pi pi-plus" severity="success" onClick={openNew} className="mx-1 " />
                <Button label="Edit" icon="pi pi-plus" severity="warning" onClick={openNew} className="mx-0.5"/> */}
                {
                    tableCrud.includes("Delete") &&
                    <Button label="Enable" icon="pi pi-check-circle" severity="success"
                        onClick={confirmDeleteSelected} disabled={!selectedRules || !selectedRules.length} className="mx-1"/>
                }

            </div>
        );
    };

    const onDeviceChange = (e, device) => {
        const val = (e.target && e.target.value) || '';
        let _deviceRule = { ...deviceRule };

        _deviceRule[`${device}`] = val;

        setDeviceRule(deviceRule);
    };

    // Tracking filtered rows
    const [filteredDevices, setFilteredDevices] = useState([]);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    // Populating Filter Options

    const models = useMemo(() => {
        return rulesObj.map(row => (row['Model']));}, [rulesObj]);
    
    const deviceIDs = useMemo(() => {
        return rulesObj.map(row => (row['deviceId']));}, [rulesObj]);
    
    const deviceVersions = useMemo(() => {
        return rulesObj.map(row => (row['DeviceVersion']));}, [rulesObj]);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilteredDevices(_filters);
        setGlobalFilterValue(value);
    };

    // Custom Filter Templates for Columns 

    const deviceIdFilterTemplate = (options) => {
        return filterTemplate(options, "Device Picker", deviceIDs, true);
    };

    const modelFilterTemplate = (options) => {
        return filterTemplate(options, "Model Picker", models, true);
    };

    const deviceVersionFilterTemplate = (options) => {
        return filterTemplate(options, "Version Picker", deviceVersions, true);
    };
    const serviceFilterTemplate = (options) => {
        return filterTemplate(options, "Service Picker", [iotRuleContext.generalStatus.SupportedServices[0]]);
    };

    // Device Table column definitions

    const deviceColumns = useMemo (() => {
        let tempDeviceColumns = {...deviceRuleTableSchema}
        tempDeviceColumns['deviceId']['filterElement'] = deviceIdFilterTemplate;
        tempDeviceColumns['service']['filterElement'] = serviceFilterTemplate;
        tempDeviceColumns['blockStatus']['filterElement'] = blockStatusFilterTemplate
        if (props.tablePage === "ViewPage"){
            tempDeviceColumns['blockStatus']['body'] = blockStatusBodyTemplate;
        }
        tempDeviceColumns['requestState']['body'] = requestStatusBodyTemplate; 
        tempDeviceColumns['region']['filterElement'] = blockRegionFilterTemplate;
        tempDeviceColumns['Model']['filterElement'] = modelFilterTemplate;
        tempDeviceColumns['DeviceVersion']['filterElement'] = deviceVersionFilterTemplate;
        return tempDeviceColumns;
        // eslint-disable-next-line
    }, []);

    if (props.tablePage === "ViewPage"){
        delete deviceColumns.requestState;
        delete deviceColumns.requestTime;
        delete deviceColumns.requestedByUser;
        delete deviceColumns.requestedByUserName;
        delete deviceColumns.lastUpdateTime;
        delete deviceColumns.lastUpdatedByUser;
        delete deviceColumns.lastUpdatedByUserName;
        delete deviceColumns.requestDescription;
        delete deviceColumns.approveDescription;
    }
    
    // Tracking column visibility
    const [visibleColumns, setVisibleColumns] = useState(() => {
        let defaultVisibleColumns = [];
        for (let columnField in deviceColumns){
            if (!('defaultVisible' in deviceColumns[columnField]) || (deviceColumns[columnField]['defaultVisible'])){
                defaultVisibleColumns.push({'field': columnField, 'header': deviceColumns[columnField]['header']});
            }
        }
        return defaultVisibleColumns;
    });

    // Populating Column picker options - ALL columns
    const columnPickerOptions = useMemo(() => {
        let tempColumnPickerOptions = [];
        for (let columnField in deviceColumns){
            tempColumnPickerOptions.push({
                'header': deviceColumns[columnField]['header'],'field': columnField
            });
            
        }
        return tempColumnPickerOptions;
        // eslint-disable-next-line
    }, []);


    // GT: dataTable use Reference
    const dt = useRef(null);


    const [filters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        id: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }]},
        service: { value: null, matchMode: FilterMatchMode.IN },
        blockStatus: { value: null, matchMode: FilterMatchMode.IN },
        region: { value: null, matchMode: FilterMatchMode.IN },
        // for MultiSelect Filter, need to use FilterMatchMode.IN
        deviceId: { value: null, matchMode: FilterMatchMode.IN },
        DeviceName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Model: { value: null, matchMode: FilterMatchMode.IN },
        SerialNumber: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        DeviceVersion: { value: null, matchMode: FilterMatchMode.IN },
        AccountName: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        requestState: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        requestTime: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        requestedByUser: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        requestedByUserName: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        lastUpdateTime: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        lastUpdatedByUser: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        lastUpdatedByUserName: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        requestDescription: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        approveDescription: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]}
    });
    const [loading, setLoading] = useState(true);

    // Loading the Table rows
    useEffect(() => {
        setDevices(rulesObj);
        setFilteredDevices(rulesObj);
        setLoading(false);
        
    }, [iotRuleContext]); // eslint-disable-line react-hooks/exhaustive-deps

    const header = renderTableHeader(dt, filteredDevices, visibleColumns,  columnPickerOptions,
         setVisibleColumns, globalFilterValue, onGlobalFilterChange, deviceTableCRUD);
    // Rendering Table columns
    const tableColumn = renderTableColumn(deviceColumns, visibleColumns, actionBodyTemplate)


    const deviceRuleDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" onClick={saveDeviceRule} />
        </React.Fragment>
    );
    const deviceRuleViewDialogFooter = (
        <React.Fragment>
            <Button label="Close" icon="pi pi-times" outlined onClick={hideViewDeviceRuleDialog} />
        </React.Fragment>
    );
    const deleteDeviceRuleFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteDeviceRuleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteDevice} />
        </React.Fragment>
    );
    const deleteDevicesRuleDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteDevicesRuleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteSelectedDevices} />
        </React.Fragment>
    );
    const rejectDeviceRuleFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideRejectDeviceRuleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={rejectDeviceRuleRequest} />
        </React.Fragment>
    );
    const rejectDevicesRuleDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideRejectDevicesRuleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={rejectSelectedRulesRequest} />
        </React.Fragment>
    );

    const approveDeviceRuleFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideApproveDeviceRuleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={approveDeviceRuleRequest} />
        </React.Fragment>
    );
    const approveDevicesRuleRequestDialogFooter = (
        <React.Fragment>

            <Button label="No" icon="pi pi-times" outlined onClick={hideApproveDevicesRuleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={approveSelectedRulesRequest} />
        </React.Fragment>
    );


    const cancelDeviceRuleFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideCancelDeviceRuleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={cancelDeviceRuleRequest} />
        </React.Fragment>
    );
    const cancelDevicesRuleDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideCancelDevicesRuleDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={cancelSelectedRulesRequest} />
        </React.Fragment>
    );

    return (
        <>
            <Toast ref={toast} />
            {Object.keys(rulesObj).length > 0 &&
            <DataTable  
                scrollable scrollHeight="800px" 
                isDataSelectable={isRowSelectable}
                selection={selectedRules} onSelectionChange={(e) => setSelectedRules(e.value)}
                tableStyle={{minWidth: "1200px"}} style={{'fontSize': '0.8em'}}
                stripedRows 
                showGridlines 
                value={devices} 
                ref={dt} 
                paginator reorderableColumns
                header={header} 
                rows={10}
                size="small"
                loading={loading}
                sortMode="multiple"
                exportFilename='DeviceBlockStatusReport'
                // multiSortMeta={multiSortMeta}
                onValueChange={filteredData => {setFilteredDevices(filteredData);}}
                resizableColumns columnResizeMode="expand" 
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                rowsPerPageOptions={[10, 25, 50, 100, 1000]}
                dataKey="ruleId" selectionMode="checkbox" 
                filters={filters} filterDisplay="menu" 
                emptyMessage="No devices found." currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">   
                {tableColumn}
            </DataTable>}
            <Dialog visible={deviceRuleDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Device Rules" modal  footer={deviceRuleDialogFooter} className="p-fluid" onHide={hideDialog}>
                    <div className="field">
                        <label htmlFor="Device" className="font-bold">
                            Device
                        </label>
                        <InputText id="device" value={deviceRule.deviceId} onChange={(e) => onDeviceChange(e, 'device')} required autoFocus className={classNames({ 'p-invalid': submitted && !deviceRule.device })} />
                        {submitted && !deviceRule.deviceId && <small className="p-error">Name is required.</small>}
                    </div>deviceRUle
            </Dialog>

            <Dialog visible={viewDeviceRuleDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Device Rule"
                modal footer={deviceRuleViewDialogFooter} onHide={hideViewDeviceRuleDialog}>
                    <div className="confirmation-content">
                        <RowTable rowData={deviceRule} tableSchema={deviceRuleTableSchema}/>
                    </div>
            </Dialog>
            {
               tableCrud.includes("Delete") && 
               <>
                <Dialog visible={deleteDeviceRuleDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm"
                modal footer={deleteDeviceRuleFooter} onHide={hideDeleteDeviceRuleDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        {deviceRule && (
                            <>
                                <span>
                                    Are you sure you want to Enable <b>{deviceRule.deviceId}</b>?
                                </span>
                                <RenderRequestDescriptionDiv description={requestDescription} setRequestDescription={setRequestDescription}/>
                            </>

                        )}
                    </div>
                </Dialog>
                <Dialog visible={deleteDevicesRuleDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm"
                modal footer={deleteDevicesRuleDialogFooter} onHide={hideDeleteDevicesRuleDialog}>
                    <div className="confirmation-content overflow-auto">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        {deviceRule && <span>Are you sure you want to Enable the Selected Devices?</span>}
                        <RenderRequestDescriptionDiv description={requestDescription} setRequestDescription={setRequestDescription}/>
                </div>
                    
            </Dialog>
               </>


            }
            {
               tableCrud.includes("Cancel") && 
               <>

            <Dialog visible={cancelDeviceRuleRequestDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm"
             modal footer={cancelDeviceRuleFooter} onHide={hideCancelDeviceRuleDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {deviceRule && (
                        <span>
                            Are you sure you want to Delete <b>{deviceRule.ip}</b> Rule Request?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={cancelDevicesRuleRequestDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm"
             modal footer={cancelDevicesRuleDialogFooter} onHide={hideCancelDevicesRuleDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {deviceRule && <span>Are you sure you want to Delete the Selected Rule Request?</span>}
                </div>
            </Dialog>
            </>
}

            {
               tableCrud.includes("Reject") && 
               <>

            <Dialog visible={rejectDeviceRuleRequestDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm"
             modal footer={rejectDeviceRuleFooter} onHide={hideRejectDeviceRuleDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {deviceRule && (
                        <>
                            <span>
                                Are you sure you want to Reject <b>{deviceRule.deviceId}</b> Rule Request?
                            </span>
                        <RenderRequestDescriptionDiv description={requestDescription} setRequestDescription={setRequestDescription}/>
                        </>

                    )}
                </div>
            </Dialog>

            <Dialog visible={rejectDevicesRuleDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm"
             modal footer={rejectDevicesRuleDialogFooter} onHide={hideRejectDevicesRuleDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {deviceRule && <span>Are you sure you want to Reject the Selected Rule Requests?</span>}
                    <RenderRequestDescriptionDiv description={requestDescription} setRequestDescription={setRequestDescription}/>
                </div>
            </Dialog>
            </>
            }
            {
               tableCrud.includes("Approve") && 
               <>

            <Dialog visible={approveDeviceRuleRequestDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm"
             modal footer={approveDeviceRuleFooter} onHide={hideApproveDeviceRuleDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {deviceRule && (
                        <>
                        <span>
                            Are you sure you want to Approve <b>{deviceRule.deviceId}</b> Rule Request?
                        </span>
                        <RenderRequestDescriptionDiv description={requestDescription} setRequestDescription={setRequestDescription}/>
                        </>

                    )}
                </div>
            </Dialog>

            <Dialog visible={approveDevicesRuleRequestDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm"
             modal footer={approveDevicesRuleRequestDialogFooter} onHide={hideApproveDevicesRuleDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {deviceRule && <span>Are you sure you want to Approve the Selected Rule Requests ?</span>}
                    <RenderRequestDescriptionDiv description={requestDescription} setRequestDescription={setRequestDescription}/>
                </div>
            </Dialog>
            </>
        }
        </>


    );
}
